import * as React from "react";
import "../Home/Home.css";
import "./clarityforteams.css";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Footer from "../HomeComponents/footer";
import Navbar from "../HomeComponents/navbar";
// team

import oneofour from "../../../assets/cutelady.png";
import oneofour1 from "../../../assets/joinhands.png";
import oneofour2 from "../../../assets/shakehand.png";
import oneofour3 from "../../../assets/typehand.png";
import firstImage from "../../../assets/7.png";
import { Link } from "react-router-dom";
import { useEffect } from "react";

export const ClarityForTeams = (props: any) => {
  useEffect(() => {
    let resultareawithtitle: string = window.location.hash;
    resultareawithtitle = resultareawithtitle.substring(1);
  }, []);
  return (
    <div>
      <Navbar />
      <Container fluid={true}>
        <Row className="firstrowcf cftcontent">
          <Col md={8} className="text-center">
            <div>
              <img src={firstImage} className="xcv1" alt="clarityforteams" />
            </div>
          </Col>
          <Col md={8}>
            <div className="Clarity-for-Teams navy">
              {" "}
              Welcome to ERIT Member Portal
            </div>
            <div className="seccfr">
              Thank you for your passion and commitment to transform the
              education sector in Nigeria.
            </div>
            <div className="seccfr">
              {" "}
              The Education Reform and Innovation Team Foundation is a dynamic
              and collaborative network with stellar benefits patriots and
              organisations ready to make a difference.
            </div>
          </Col>
          <Col md={8} className="lko11wr">
            <Row className="lko11">
              <Col md={6}>
                <img src={oneofour1} alt="oneofour" className="oneofour" />
                <div className="understand navy">
                  Shape the Educational Landscape{" "}
                </div>
                <div className="Byhelping">
                  Join a movement of patriots and organisations with a strong
                  national culture shaping the educational landscape of a future-ready generation.
                </div>
              </Col>
              <Col md={6}>
                <img src={oneofour2} alt="oneofour" className="oneofour" />
                <div className="understand navy">
                  Facilitate Sustainable Change{" "}
                </div>
                <div className="Byhelping">
                  Our mission is to drive the reconstruction and establishment
                  of sustainable educational policies, systems and structures
                  through strategic alliances.
                </div>
              </Col>
              <Col md={6}>
                <img src={oneofour} alt="oneofour" className="oneofour" />
                <div className="understand navy">Future Ready Generation </div>
                <div className="Byhelping">
                  The Education Reform and Innovation Team Foundation's vision
                  is to facilitate the emergence of a values-driven, future-ready education for every Nigerian Learner.
                </div>
              </Col>
              <Col md={6}>
                <img src={oneofour3} alt="oneofour" className="oneofour" />
                <div className="understand navy">Less Talk More Action </div>
                <div className="Byhelping">
                  At ERIT we uphold a productivity culture; one that focuses on
                  doing and which requires all stakeholders to continually
                  collaborate and strategically Partner to improve learning
                  outcomes of the Nigerian learner which in turn transforms the
                  educational ecosystem.
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="firstrowcf cftcontent bgwcf offe11">
          <Col md={12}>
            <div className="Clarity-for-Teams navy offe" id="plans">
              {" "}
              Join The ERIT Network
            </div>
          </Col>
          <Col md={12} className="ing1">
            We invite you to join us on this exciting journey
          </Col>
          {/* <div className="pricewraper">
            <div className="plan_">Member</div>
            <div className="personalityss">0-3 years experience </div>
            <br />
            <div className="personality dd11">
              Create a professional profile
            </div>
            <div className="personality dd11">
              Access internal publications and newsletters
            </div>
            <div className="Strengths dd11">Join Blogs/Forums/Webinars </div>
            <div className="Weaknesses dd11">Publish your CV/Resume </div>
            <div className="Leadership dd11">
              {" "}
              Bi-annual Competence Assessment
            </div>
            <div className="Leadership dd11">
              Discounted workshops and trainings.{" "}
            </div>
            <div className="Leadership dd11">
              Opportunities to work on volunteer & paid educational reform
              projects
            </div>
            <div className="amount12a">
              {" "}
              <b> NGN 5,000</b>
              <span className="period1">Annually</span>
            </div>
            <div>
              <Link className="amlink" to={`/signup/${5000}`}>
                {" "}
                <button className="getstarted">Get Started</button>
              </Link>
            </div>
          </div> */}
          <div className="pricewraper">
            <div className="plan_">Community Member</div>
            <br />
            <div className="personality dd11"> </div>
            <div className="personality dd11">
              Create a professional profile
            </div>
            <div className="personality dd11">
              Access internal publications and newsletters
            </div>
            <div className="Strengths dd11">Join Blogs/Forums/Webinars </div>
            <div className="Weaknesses dd11">Publish your CV/Resume </div>
            <div className="Leadership dd11">
              {" "}
              Periodic Competence Assessment
            </div>
            <div className="Leadership dd11">Join Thematic Groups</div>
            <div className="Leadership dd11">
              Discounted workshops and trainings
            </div>
            <div className="Leadership dd11">
              Opportunities to volunteer on education reform projects
            </div>
            <div className="amount12a">
              {" "}
              <b className="nobreak" > NGN 10,000</b>
              <span className="period1">Annually </span>
            </div>
            <div>
              <Link className="amlink" to={`/signup`}>
                {" "}
                <button className="getstarted">Get Started</button>
              </Link>
            </div>
          </div>
          <div className="pricewraper">
            <div className="plan_">Professional Member</div>
            <br />
            <div className="personality dd11"></div>
            <div className="personality dd11">
              Create a professional profile
            </div>
            <div className="personality dd11">
              Access internal publications and newsletters
            </div>
            <div className="Strengths dd11">Join Blogs/Forums/Webinars </div>
            <div className="Weaknesses dd11">Publish your CV/Resume </div>
            <div className="Strongw dd11">Join Thematic Groups </div>
            <div className="Strongw dd11">Periodic Competence Assessment</div>
            <div className="Strongw dd11">Join Thematic Groups </div>
            <div className="Strongw dd11">
              Discounted workshops and trainings
            </div>
            <div className="Strongw dd11">
              Opportunities to earn on education reform projects
            </div>
            <div className="Leadership dd11">
              Lists services and products on our website + Discounted
              advertising (20%)
            </div>
            <div className="Leadership dd11">Referrals </div>
            <div className="amount12a">
              {" "}
              <b className="nobreak" > NGN 25,000 </b>
              <span className="period1">Annually</span>
            </div>
            <div>
              <Link className="amlink" to={`/signup/${25000}`}>
                {" "}
                <button className="getstarted">Get Started</button>
              </Link>
            </div>
          </div>

          <div className="pricewraper">
            <div className="plan_">Corporate Member</div>
            <br />
            <div className="Strengths dd11">Founder/CEO</div>
            <div className="Strengths dd11">5 team members </div>
            <div className="Strengths dd11"> Create professional profiles </div>
            <div className="Strengths dd11">
              {" "}
              Access internal publications and newsletters
            </div>
            <div className="Strengths dd11">Join Blogs/Forums/Webinars </div>
            <div className="Weaknesses dd11">Publish your CV/Resume </div>
            <div className="Strongw dd11">Join Thematic Groups </div>
            <div className="Leadership dd11">Banner Advertising (50%) </div>
            <div className="Leadership dd11">
              Periodic Competence Assessment for Organisation members{" "}
            </div>
            <div className="Leadership dd11">
              Publish CV/Resume of Organisation members
            </div>
            <div className="Leadership dd11">Join Thematic Groups </div>
            <div className="Leadership dd11">
              Discounted workshops and trainings
            </div>
            <div className="Leadership dd11">
              Opportunities to collaborate and earn on education reform projects
            </div>
            <div className="Leadership dd11">
              Lists services and products on our website + Discounted
              advertising (50%)
            </div>
            <div className="Leadership dd11">Referrals </div>
            <div className="Leadership dd11">
              Host Events & Exhibit at Events
            </div>
            <div className="Leadership dd11">
              Represent ERIT at designated events
            </div>
            <div className="Leadership dd11">Join Business Round-tables</div>
            <div className="Leadership dd11">Co-Host Webinar Presentations</div>
            <div className="amount12a">
              {" "}
                <b className="nobreak"> NGN 100,000 </b>
              <div className="period1">Annually</div>
            </div>
            <div className="Strongw dd11">
              <Link className="amlink" to={`/signup/${100000}`}>
                <button className="getstarted1"> Get Started</button>
              </Link>
            </div>
          </div>
        </Row>
        <Footer />
      </Container>
    </div>
  );
};

import * as React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./../kegennidashboard.css";
import DashboardLargeScreenNav from "../DashboardLargeScreenNav";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import userimg from "../../../assets/userimg.png";
import newmsg from "../../../assets/newmsg.svg";
import searchicon from "../../../assets/searchicon.png";
import OrganzationSideBar from './OrganizationSideBar';
import OrganizationDashboardNavBar from './OrganizationDashboardNavBar';

const OrganizationDashboardMessages = () => {
  return (
    <Container fluid={true} className="contann122">
      <OrganizationDashboardNavBar overview={true} />
      <Row>
        <OrganzationSideBar overview={true} />
        <Col md={10} sm={12} className="prm">
          <DashboardLargeScreenNav title="Messages"/>
          <Row>
            <Col md={12} className="kisls">
              <div className="kdashheader npps nuhead">
                <div className="fjss">
                  <div>
                    {" "}
                    <span className="kdashheaderlight idds">
                      Hi <span className="ksname"> Organization Name</span>
                      <span> (organizationID)</span>
                    </span>
                  </div>
                </div>
                <div className="searchbar">
                  <img className="searchicon" src={searchicon} alt="search" />
                  <input type="search" className="searchit" placeholder="Find Team Members by Name" />
                </div>
              </div>
              <div className="messageerit findbelow msgsum">
                Summary of all your messages to <span className="init">ERIT</span> and Team Members
              </div>
              <div className="msgs">
                  <div className="fromerit">
                    <div className="userr">
                        <img className="user_image" src={userimg} alt="user image" />
                    </div>
                    <div className="userrdet">
                        <div>
                            <span className="userrdet1">ERIT {" "}</span>
                            <span className="userrdet2">jaiye@user.com</span>
                        </div>
                        <div className="userrdet3">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id mi, mattis at ipsum ullamcorper blandit pharetra. 
                        Est elit, morbi elementum faucibus nec morbi eget aliquet adipiscing. Sapien urna volutpat mattis cursus non 
                        et mauris tellus laoreet. Metus potenti leo nulla nulla pretium id.
                        </div>
                    </div>
                    <div  className="userrmsg">6 days Ago</div>
                  </div>
              </div>
              <div className="msgs">
                  <div className="fromerit">
                    <div className="userr">
                        <img className="user_image" src={userimg} alt="user image" />
                    </div>
                    <div className="userrdet">
                        <div>
                            <span className="userrdet1">ERIT {" "}</span>
                            <span className="userrdet2">jaiye@user.com</span>
                        </div>
                        <div className="userrdet3">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id mi, mattis at ipsum ullamcorper blandit pharetra. 
                        Est elit, morbi elementum faucibus nec morbi eget aliquet adipiscing. Sapien urna volutpat mattis cursus non 
                        et mauris tellus laoreet. Metus potenti leo nulla nulla pretium id.
                        </div>
                    </div>
                    <div  className="userrmsg">6 days Ago</div>
                  </div>
              </div>
              <div className="msgs">
                  <div className="fromerit">
                    <div className="userr">
                        <img className="user_image" src={userimg} alt="user image" />
                    </div>
                    <div className="userrdet">
                        <div>
                            <span className="userrdet1">ERIT {" "}</span>
                            <span className="userrdet2">jaiye@user.com</span>
                        </div>
                        <div className="userrdet3">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id mi, mattis at ipsum ullamcorper blandit pharetra. 
                        Est elit, morbi elementum faucibus nec morbi eget aliquet adipiscing. Sapien urna volutpat mattis cursus non 
                        et mauris tellus laoreet. Metus potenti leo nulla nulla pretium id.
                        </div>
                    </div>
                    <div  className="userrmsg">6 days Ago</div>
                  </div>
              </div>
              <Link to="">
                <Button className="addmsg">
                  <img src={newmsg} className="msgplus" alt="new message"/>
                </Button>
                </Link>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default OrganizationDashboardMessages;

import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import "./style.css";
import onboarding5 from  "../../assets/onboarding5.svg";

const OrganizationOnboardingModal1 =()=>{
    const [state, setState] = useState({isOpen: true});
    const closeModal =()=> {
        setState({isOpen: true});
    }
    return (
        <div>
            <Modal
              show={state.isOpen}
              centered={true}
              className="individualonb"
              onHide={closeModal}
            >
              <Modal.Body>
                <div className="text-center">
                  {" "}
                  {" "}
                </div>
                <div className="skip">
                    <Link to="/overview">Skip</Link>
                </div>
                <div className="welcome"> Welcome <span className="user_name" >OrganizationName</span> to the ERIT experience!</div>
                <div className="intro">
                  To get paired on reform projects, lets take you through the following simple steps
                </div>
                <div className="image">
                <img className="build_img" src={onboarding5} alt="start building"/>
                </div>
                <div className="build">Invite Team Members</div>
                <div className="descrip">You need to provide more details about you and your interests, to enable us match you to projects that suit your specific skill set</div>
                <div className="scroll">
                    <span className="rect">{" "}</span>
                    <span className="circle">{" "}</span>
                    <span className="circle">{" "}</span>
                    <span className="text-center">
                        <div className="next">
                            <Link to="/organizationonboarding2">Next</Link>
                        </div>
                    </span>
                </div>
              </Modal.Body>
            </Modal>
        </div>
    )
}

export default OrganizationOnboardingModal1;
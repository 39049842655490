import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import "./style.css";
import onboarding4 from "../../assets/onboarding4.svg";

const OrganizationOnboardingModal3 = () => {
  const [state, setState] = useState({ isOpen: true });
  const closeModal = () => {
    setState({ isOpen: true });
  };
  return (
    <div>
      <Modal show={state.isOpen} centered={true} onHide={closeModal}>
        <Modal.Body>
          <div className="text-center"> </div>
          <div className="skip">
            <Link to="/overview">Skip</Link>
          </div>
          <div className="welcome">
            {" "}
            Welcome <span className="user_name">OrganizationName</span> to the ERIT
            experience!
          </div>
          <div className="intro">
            To get paired on reform projects, lets take you through the
            following simple steps
          </div>
          <div className="image">
            <img className="build_img" src={onboarding4} alt="start building" />
          </div>
          <div className="build">Notify an ERIT Admin</div>
          <div className="descrip">
            Quickly use our messaging feature to drop us a message after
            completing your profile, and be ready to get paired to great
            opportunities
          </div>
          <div className="scroll">
            <span className="circle"> </span>
            <span className="circle"> </span>
            <span className="rect"> </span>
            <span className="text-center">
              <div className="next">
                <Link to="/">Finish</Link>
              </div>
              <div className="prev">
                <Link to="/organizationonboarding2">Prev</Link>
              </div>
            </span>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default OrganizationOnboardingModal3;

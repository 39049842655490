import * as React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import "./employersview.css";
import axios, { AxiosResponse } from "axios";
import { API } from "../../../config";
import formemail from "../../../assets/formemail.png";
import { Link } from "react-router-dom";
import Footer from "../HomeComponents/footer";
import Navbar from "../HomeComponents/navbar";
import demoLogo from "../../../assets/clarity.png";
import { useState } from "react";

interface State {
  firstname: string;
  email: string;
  lastname: string;
  successMsg: string;
  errorMessage: string;
  password: string;
  isLoading: boolean;
  kigenni: boolean;
  profession: string;
  token: string;
}
const EmployersViewCheckIn: React.FunctionComponent = (props: any) => {
  const [state, setFormState] = useState<State>({
    firstname: "",
    email: "",
    lastname: "",
    errorMessage: "",
    successMsg: "",
    isLoading: false,
    kigenni: true,
    password: "",
    profession: "",
    token: "",
  });
  const { firstname, email, errorMessage, successMsg, isLoading } = state;
  const sendFormData = (e) => {
    e.preventDefault();
    setFormState({ ...state, isLoading: true });
    const data = {
      email,
    };
    axios
      .post<any, AxiosResponse<any>>(`${API}/employersview`, data)
      .then((response) => {
        //console.log(response);
        localStorage.setItem("result", JSON.stringify(response.data[0]));
        if (response.status === 200) {
          setFormState({
            ...state,
            successMsg: response?.data[0]?.message,
            isLoading: false,
            token: response?.data[0].token,
          });
        }
        props.history.push("/employers/result");
      })
      .catch((error) => {
        //console.log(error.response);
        if (error && error.response && error.response.data) {
          return setFormState({
            ...state,
            errorMessage: error.response.data[0].message,
            isLoading: false,
          });
        }
        if (error && error.response && error.response.status === 400) {
          return setFormState({
            ...state,
            errorMessage: error.response.data[0].message,
            isLoading: false,
          });
        }
        setFormState({
          ...state,
          errorMessage: "failed",
          isLoading: false,
        });
      });
  };

  const changeActionOnFormData = (e: any) => {
    setFormState({
      ...state,
      [e.target.name]: e.target.value,
      errorMessage: "",
      successMsg: "",
    });
  };

  return (
    <>
      <Navbar />
      <Container fluid={true}>
        <Row className="kli bcbv">
          <Col md={4}>
            <div className=" mjcn"></div>
            {errorMessage && (
              <Alert key={2} variant="danger">
                {errorMessage}
              </Alert>
            )}
            {successMsg && (
              <Alert key={2} variant="info">
                {successMsg}
              </Alert>
            )}
            <Form onSubmit={sendFormData}>
              <Form.Group className="bvbcm" controlId="formBasicEmail">
                <img src={formemail} className="formavatar" alt="formavatar" />
                <Form.Control
                  type="email"
                  className="field3"
                  value={email}
                  name="email"
                  onChange={changeActionOnFormData}
                  placeholder="Email Address"
                />
              </Form.Group>
              <Form.Group></Form.Group>
              <Button variant="primary" className="subbtn ncn" type="submit">
                {!isLoading ? "View Assessment Result" : "Processing ..."}
              </Button>
            </Form>
          </Col>
        </Row>
        <Footer />
      </Container>
    </>
  );
};

export default EmployersViewCheckIn;

import * as React from "react";
import Calendar from "react-calendar";
import "../Dashboard/dashboard.css";
import Axios from "axios";
import { API } from "../../config";
import preloader from "../../assets/preloader.gif";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Modal } from "react-bootstrap";
import { useHistory } from 'react-router-dom';

const IndividualAppointment = () => {
  const [state, setState] = React.useState<any>({
    appointmentTime: "",
    appointmentDate: "",
    availableTime: [],
    availableDay: [],
    availableDayNo: [],
    isLoading: true,
    isLoading2: false,
    selected: "",
  });
  const [modState, setModState] = React.useState<any>(false);
  const openSuccessModal = () => {
    setModState(true);
  };
  const closeSuccessModal = () => {
    setModState(true);
  };
  const {
    appointmentTime,
    appointmentDate,
    availableTime,
    availableDay,
    availableDayNo,
    isLoading,
    isLoading2,
    selected,
  } = state;
  React.useEffect(() => {
    const availableToken = localStorage.getItem("userToken");
    const token = availableToken
      ? JSON.parse(availableToken)
      : window.location.assign("/signin");
    const data = {
      date: appointmentDate,
    };
    Axios.all([
      Axios.get(`${API}/dashboard/get-available-days`, {
        headers: { Authorization: `Token ${token}` },
      }),
    ])
      .then(
        Axios.spread((response) => {
          //console.log(response);

          if (response.status === 200) {
            let days = response.data.available_days;
            let daysNo: any = [];
            // days = ["monday", "tuesday", "wednesday"];
            days.map((eachday) => {
              if (eachday === "monday") {
                daysNo.push(1);
              }
              if (eachday === "tuesday") {
                daysNo.push(2);
              }
              if (eachday === "wednesday") {
                daysNo.push(3);
              }
              if (eachday === "thursday") {
                daysNo.push(4);
              }
              if (eachday === "friday") {
                daysNo.push(5);
              }
              if (eachday === "saturday") {
                daysNo.push(6);
              }
              if (eachday === "sunday") {
                daysNo.push(0);
              }
            });
            //console.log(daysNo);

            setState({
              ...state,
              availableDay: days,
              availableDayNo: daysNo,
              isLoading: false,
            });
          } else {
            setState({
              ...state,
              isLoading: false,
            });
          }
        })
      )
      .catch((err) => {});
  }, []);
  const onChangeTime = (e) => {
    if(e.target.checked) {
      setState({
        ...state,
        [e.target.name]: e.target.value,
        appointmentTime: e.target.value,
        selected: e.target.value,
      });
    }
  };
  //console.log({"date": appointmentDate})
  //console.log({"time": appointmentTime})
  
  const onchangeDate = (value, event) => {
    //console.log(value.getDay());
    let day = value.getDate();
    let month = ("0" + (value.getMonth() + 1)).slice(-2);
    let year = value.getFullYear();
    setState({
      ...state,
      appointmentDate: `${year}-${month}-${day}`,
      isLoading2: true,
    });
    const availableToken = localStorage.getItem("userToken");
    const token = availableToken
      ? JSON.parse(availableToken)
      : window.location.assign("/signin");
    const data = {
      date: `${year}-${month}-${day}`,
    };
    Axios.all([
      Axios.post(`${API}/dashboard/get-available-time/`, data, {
        headers: { Authorization: `Token ${token}` },
      }),
    ])
      .then(
        Axios.spread((res) => {
          //console.log(res);

          if (res.status === 200) {
            setState({
              ...state,
              isLoading2: false,
              appointmentDate: `${year}-${month}-${day}`,
              availableTime: res.data.available_time,
              appointmentTime: "",
              selected: "",
              // availableTime: [
              //   "09:00 am - 10:00am",
              //   "10:00 am - 11:00am",
              //   "11:00 am - 12:00pm",
              // ],
            });
          }
        })
      )
      .catch((err) => {});
  };
  // //console.log(availableTime)
  const sendProposedDate = () => {
    if (appointmentDate === "") {
      //console.log("Please select a date.");
      toast("Please select a date!");
      return;
    }
    if (appointmentTime === "") {
      //console.log("Please select a time.");
      notify("Please select a time!");
      return;
    }
    const availableToken = localStorage.getItem("userToken");
    const token = availableToken
      ? JSON.parse(availableToken)
      : window.location.assign("/signin");
    const data = {
      date: appointmentDate,
      time: appointmentTime,
    };
    //console.log(data);
    //console.log(appointmentDate);
    //console.log(appointmentTime);
    Axios.all([
      Axios.post(`${API}/dashboard/user-appointment/`, data, {
        headers: { Authorization: `Token ${token}` },
      }),
    ])
      .then(
        Axios.spread((response) => {
          openSuccessModal();
        })
      )
      .catch((err) => {});
  };
  const redirect = () => {
    return window.location.assign("/overview");
  };
  const history = useHistory();
  const back = () => {
    return history.goBack();
  }
  const notify = (message: string) => toast(message, { containerId: "B" });
  const pickClass = (date) => {
    //console.log(appointmentDate)
    let dayClick = date.date.getDate();
    let monthClick = ("0" + (date.date.getMonth() + 1)).slice(-2);
    let yearClick = date.date.getFullYear();
    let allDates = `${yearClick}-${monthClick}-${dayClick}`;
    if(appointmentDate === allDates) {
      return "selectedDate";
    }
  }
  return (
    <>
      <div className="indscheduler">
        {(isLoading || isLoading2) && (
          <div className="apptpreloader1">
            <img src={preloader} className="apptpreloader" alt="" />
          </div>
        )}
        <button className="apptbackbtn" onClick={back}>&#8592; Back</button>
        <div className="schhead">
          <div className="indttl">Schedule an Appointment</div>
          <div className="indtxt">
            Select a suitable day and time for your client appointments
          </div>
        </div>
        <div>
          <div className="schpref indschdates">
            <div>
              <div className="slctttl indcalttl">
                Pick a date from the calender
              </div>
              <div className="calslct">
                <Calendar
                  tileDisabled={({ date }) =>
                    availableDayNo.indexOf(date.getDay()) === -1
                  }
                  value={new Date()}
                  tileClassName={pickClass}
                  onChange={onchangeDate}
                />
              </div>
            </div>
            <div className="timeslct">
              <div className="slctttl indcalttl">Select time of day</div>
              <div className="dayslct calslct">
                <label htmlFor="time1" className="dayslct1">
                  <input
                    id="time1"
                    className="selection"
                    type="radio"
                    name="time"
                    value="09:00 am - 10:00am"
                    onChange={onChangeTime}
                    disabled={
                      availableTime.indexOf("09:00 am - 10:00am") === -1
                    }
                    checked={selected === "09:00 am - 10:00am"}
                  />{" "}
                  <span className="daytxt">09:00 am - 10:00am</span>
                </label>
                <label htmlFor="time2" className="dayslct2">
                  <input
                    id="time2"
                    className="selection"
                    type="radio"
                    name="time"
                    value="10:00 am - 11:00am"
                    onChange={onChangeTime}
                    disabled={
                      availableTime.indexOf("10:00 am - 11:00am") === -1
                    }
                    checked={selected === "10:00 am - 11:00am"}
                  />{" "}
                  <span className="daytxt">10:00 am - 11:00am</span>
                </label>
                <label htmlFor="time3" className="dayslct2">
                  <input
                    id="time3"
                    className="selection"
                    type="radio"
                    name="time"
                    value="11:00 am - 12:00pm"
                    onChange={onChangeTime}
                    disabled={
                      availableTime.indexOf("11:00 am - 12:00pm") === -1
                    }
                    checked={selected === "11:00 am - 12:00pm"}
                  />{" "}
                  <span className="daytxt">11:00 am - 12:00pm</span>
                </label>
                <label htmlFor="time4" className="dayslct2">
                  <input
                    id="time4"
                    className="selection"
                    type="radio"
                    name="time"
                    value="12:00 pm - 01:00pm"
                    onChange={onChangeTime}
                    disabled={
                      availableTime.indexOf("12:00 pm - 01:00pm") === -1
                    }
                    checked={selected === "12:00 pm - 01:00pm"}
                  />{" "}
                  <span className="daytxt">12:00 pm - 01:00pm</span>
                </label>
                <label htmlFor="time5" className="dayslct2">
                  <input
                    id="time5"
                    className="selection"
                    type="radio"
                    name="time"
                    value="01:00 pm - 02:00pm"
                    onChange={onChangeTime}
                    disabled={
                      availableTime.indexOf("01:00 pm - 02:00pm") === -1
                    }
                    checked={selected === "01:00 pm - 02:00pm"}
                  />{" "}
                  <span className="daytxt">01:00 pm - 02:00pm</span>
                </label>
                <label htmlFor="time6" className="dayslct2">
                  <input
                    id="time6"
                    className="selection"
                    type="radio"
                    name="time"
                    value="02:00 pm - 03:00pm"
                    onChange={onChangeTime}
                    disabled={
                      availableTime.indexOf("02:00 pm - 03:00pm") === -1
                    }
                    checked={selected === "02:00 pm - 03:00pm"}
                  />{" "}
                  <span className="daytxt">02:00 pm - 03:00pm</span>
                </label>
                <label htmlFor="time7" className="dayslct2">
                  <input
                    id="time7"
                    className="selection"
                    type="radio"
                    name="time"
                    value="03:00 pm - 04:00pm"
                    onChange={onChangeTime}
                    disabled={
                      availableTime.indexOf("03:00 pm - 04:00pm") === -1
                    }
                    checked={selected === "03:00 pm - 04:00pm"}
                  />{" "}
                  <span className="daytxt">03:00 pm - 04:00pm</span>
                </label>
                <label htmlFor="time8" className="dayslct3">
                  <input
                    id="time8"
                    className="selection"
                    type="radio"
                    name="time"
                    value="04:00 am - 05:00pm"
                    onChange={onChangeTime}
                    disabled={
                      availableTime.indexOf("04:00 am - 05:00pm") === -1
                    }
                    checked={selected === "04:00 am - 05:00pm"}
                  />{" "}
                  <span className="daytxt">04:00 am - 05:00pm</span>
                </label>
              </div>
            </div>
          </div>
          <div className="sendbtnsec">
            <button className="senddate" onClick={sendProposedDate}>
              Send Proposed Date
            </button>
          </div>
        </div>
      </div>
      <ToastContainer
        enableMultiContainer
        containerId={"B"}
        toastClassName="bg-info text-white"
        hideProgressBar={true}
        position={toast.POSITION.TOP_CENTER}
      />
      <Modal
        show={modState}
        className="success-mod"
        onHide={closeSuccessModal}
        centered
      >
        <Modal.Body>
          <div className="success-modtxt1">Successful!</div>
          <div className="success-modtxt2">
            You will receive a confirmation mail when your appointment is
            approved.
          </div>
          <button className="success-modbtn" onClick={redirect}>
            Next
          </button>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default IndividualAppointment;

import * as React from "react";
//bstp
import "./footer.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// footer statics
import facebook from "../../../assets/facebook.png";
import twitter from "../../../assets/twitter.png";
import instagram from "../../../assets/instagram.png";
import { Link } from "react-router-dom";
import linkedin from "../../../assets/linkedin.png";
import nysc from "../../../assets/nysc.png";
import Yudimy from "../../../assets/yudimyemaillogo.png";
import saed from "../../../assets/saedconnect.png";

const Footer: React.FC = (props) => {
  return (
    <div>
      <Row>
        <Col md={12} className="eritydyfooter">
          {/* <div className="reserved">
            <div className="powerdby">
              <span className="poweredbytxt">Powered by</span>
              <span className="style11">
                <a href="/yudimy.com" target="_blank" className="linkphone">
                  <img src={Yudimy} className="yudimynysc" alt="nysc logo" />{" "}
                </a>
              </span>{" "}
            </div>
          </div> */}
          <div>
          <div className="powerdby">
              <span className="poweredbytxt">Powered by</span>
              <span className="style11">
                <a href="https://yudimy.com/" target="_blank" className="linkphone">
                  <img src={Yudimy} className="yudimynysc" alt="nysc logo" />{" "}
                </a>
              </span>{" "}
            </div>
          </div>
        </Col>
      </Row>
      {/* footer starts here */}
    </div>
  );
};

export default Footer;

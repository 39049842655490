import * as React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./../kegennidashboard.css";
import DashboardLargeScreenNav from "../DashboardLargeScreenNav";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import userimg from "../../../assets/userimg.png";
import OrganzationSideBar from './OrganizationSideBar';
import OrganizationDashboardNavBar from './OrganizationDashboardNavBar';


const OrganizationDashboardOverview = () => {
  return (
    <Container fluid={true} className="contann122">
      <OrganizationDashboardNavBar overview={true} />
      <Row>
        <OrganzationSideBar overview={true} />
        <Col md={10} sm={12} className="prm">
          <DashboardLargeScreenNav title="Overview" />
          <Row>
            <Col md={12} className="kisls">
              <div className="kdashheader npps">
                <div className="fjss">
                  <div>
                    {" "}
                    <span className="kdashheaderlight idds">
                      Hi <span className="ksname"> Organization Name</span>
                      <span> (organizationID)</span>
                    </span>
                  </div>
                </div>
              </div>
              <div className="messageerit findbelow">
                Find below summary, charts and overall overview of your Team
                Members
              </div>
              <div className="wwrap">
                <div className="fourinfo">
                  <div className="firstoffour totals">
                    <div className="fouri1"></div>
                    <div className="fouri1a">
                      <div className="mmber">Total Invitations Sent </div>
                      <div className="mmber1">60</div>
                    </div>
                  </div>
                  <div className="firstoffour totals">
                    <div className="fouri1"></div>
                    <div className="fouri1a">
                      <div className="mmber">Total Sign Ups</div>
                      <div className="mmber1">53</div>
                    </div>
                  </div>
                  <div className="firstoffour totals">
                    <div className="fouri1"></div>
                    <div className="fouri1a">
                      <div className="mmber">Total Completed</div>
                      <div className="mmber1">43</div>
                    </div>
                  </div>
                  <div className="firstoffour totals">
                    <div className="fouri1"></div>
                    <div className="fouri1a">
                      <div className="mmber">Uncompleted</div>
                      <div className="mmber1">7</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="buttn">
              <Link to="">
                <Button className="viewmem">View Members</Button>
                </Link>
                <Link to="">
                <Button className="sendIV">Send Invite</Button>
                </Link>
              </div>
              <hr className="vider"/>
              <div className="msgfrom">Messages from ERIT</div>
              <div className="msgs">
                  <div className="fromerit">
                    <div className="userr">
                        <img className="user_image" src={userimg} alt="user image" />
                    </div>
                    <div className="userrdet">
                        <div>
                            <span className="userrdet1">ERIT {" "}</span>
                            <span className="userrdet2">jaiye@user.com</span>
                        </div>
                        <div className="userrdet3">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id mi, mattis at ipsum ullamcorper blandit pharetra. 
                        Est elit, morbi elementum faucibus nec morbi eget aliquet adipiscing. Sapien urna volutpat mattis cursus non 
                        et mauris tellus laoreet. Metus potenti leo nulla nulla pretium id.
                        </div>
                    </div>
                    <div  className="userrmsg">6 days Ago</div>
                  </div>
              </div>
              <div className="msgs">
                  <div className="fromerit">
                    <div className="userr">
                        <img className="user_image" src={userimg} alt="user image" />
                    </div>
                    <div className="userrdet">
                        <div>
                            <span className="userrdet1">ERIT {" "}</span>
                            <span className="userrdet2">jaiye@user.com</span>
                        </div>
                        <div className="userrdet3">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id mi, mattis at ipsum ullamcorper blandit pharetra. 
                        Est elit, morbi elementum faucibus nec morbi eget aliquet adipiscing. Sapien urna volutpat mattis cursus non 
                        et mauris tellus laoreet. Metus potenti leo nulla nulla pretium id.
                        </div>
                    </div>
                    <div  className="userrmsg">6 days Ago</div>
                  </div>
              </div>
              <Link to="">
                <Button className="viewmem vwall">View All</Button>
                </Link>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default OrganizationDashboardOverview;

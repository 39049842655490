import * as React from "react";
import Navbar from "../HomeComponents/navbar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import Footer from "../HomeComponents/footer";
import Col from "react-bootstrap/Col";
import "../SignUp/signup.css";
import "./PaymentSummary.css";
import signinwelcome from "../../../assets/3.jpg";
import fb from "../../../assets/fbsignup.png";
import google from "../../../assets/google.png";
import linkedin from "../../../assets/linked.png";
import { Link, withRouter } from "react-router-dom";
import axios, { AxiosResponse } from "axios";
import { API } from "../../../config";
import GoogleLogin from "react-google-login";
import { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface State {
  email: string;
  password: string;
  first_name: string;
  errorMessage: string;
  last_name: string;
  user: Array<any>;
  isLoading: boolean;
  amount: string;
}
const notify = (message: string) => {
  toast(message, { containerId: "a " });
};
const EritPaymentSummary = withRouter((props: any) => {
  const [state, setFormState] = React.useState<State>({
    email: "",
    password: "",
    first_name: "",
    last_name: "",
    errorMessage: "",
    amount: "",
    user: [],
    isLoading: false,
  });
  const {
    email,
    password,
    errorMessage,
    isLoading,
    first_name,
    last_name,
    amount,
  } = state;
  useEffect(() => {
    window.scrollTo(-0, -0);
    setFormState({
      ...state,
      amount: props.amount,
    });
    //console.log(props.description);
    getUserInfo();
  }, []);
  const getUserInfo = (): void => {
    const availableToken = localStorage.getItem("userToken");
    const token = availableToken
      ? JSON.parse(availableToken)
      : props.history.push("/signin");
    axios
      .get(`${API}/currentuser`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => {
        //console.log(response);
        if (response.status === 200) {
          localStorage.setItem("user", JSON.stringify(response?.data));
          setFormState({
            ...response.data[0],
          });
        }
      })
      .catch((error) => {
        //console.log(error);
      });
  };
  const sendFormData = () => {
    setFormState({ ...state, isLoading: true });
    const data = {
      email,
      password,
    };
    //console.log(API);
    axios
      .post<any, AxiosResponse<any>>(`${API}/accounts/login/`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setFormState({
          ...state,
          isLoading: false,
        });
      })
      .catch((error) => {
        //console.log(error.response);
        if (error && error.response && error.response.data) {
          return setFormState({
            ...state,
            errorMessage: error?.response?.data[0]?.message,
            isLoading: false,
          });
        }
        if (error && error?.response?.status === 404) {
          return setFormState({
            ...state,
            errorMessage: error?.response?.data.error,
            isLoading: false,
          });
        }
        setFormState({
          ...state,
          errorMessage: "Failed to Login",
          isLoading: false,
        });
      });
  };

  const changeActionOnFormData = (e: any) => {
    setFormState({
      ...state,
      [e.target.name]: e.target.value,
      errorMessage: "",
    });
  };
  const payWithMonnify = (reference) => {
    const availableUser = localStorage.getItem("user");
    var user = availableUser
      ? JSON.parse(availableUser)
      : props.history.push("/signin");
    try {
      //console.log(props.description);
      window.MonnifySDK.initialize({
        amount: props.amount,
        currency: "NGN",
        reference,
        customerFullName: user[0]?.first_name + "  " + user[0]?.last_name,
        // customerEmail: "monnify@monnify.com",
        customerEmail: user[0]?.email,
        customerMobileNumber: "",
        apiKey: "MK_PROD_YXUZDA5RGJ",
        contractCode: "664432419424",
        // apiKey: "MK_TEST_PCQ3ULNVBZ",
        // contractCode: "8117484383",
        paymentDescription:
          props.description === "Professional"
            ? "Professional Membership Plan"
            : props.description === "Community"
            ? "Community Membership Plan"
            : props.description === "Corporate"
            ? "Corporate Membership Plan"
            : "Invalid Plan",
        isTestMode: false,
        onComplete: function (response) {
          if (response.paymentStatus == "OVERPAID") {
            notify(
              "You current payment has exceeded the amount. The excess amount will be refunded within 24 hours"
            );
            return setTimeout(() => (window.location.pathname = "/"), 3000);
          }
          if (response.paymentStatus === "PAID") {
            // //console.log(response)
            return setTimeout(
              () => (window.location.pathname = "/team_reg"),
              3000
            );
          }
          if (response.paymentStatus == "PENDING") {
            notify("Payment Pending");
            return setTimeout(() => (window.location.pathname = "/"), 9000);
          }
        },
        onClose: function (data) {
          return setTimeout(() => notify("Payment Pending"), 9000);
        },
      });
    } catch (error) {
      //console.log("Failed to initailize payment" + error);
    }
  };
  // live keys
  // const payWithMonnify = (reference) => {
  //   const availableUser = localStorage.getItem("user");
  //   var user = availableUser
  //     ? JSON.parse(availableUser)
  //     : props.history.push("/signin");
  //   try {
  //     window.MonnifySDK.initialize({
  //       amount: props.amount,
  //       currency: "NGN",
  //       reference,
  //       customerFullName: user[0]?.first_name + "  " + user[0]?.last_name,
  //       customerEmail: "monnify@monnify.com",
  //       customerMobileNumber: "",
  //       apiKey: "MK_PROD_NNSGXTY6LF",
  //       contractCode: "722431733218",
  //        paymentDescription:
          // props.description === "Professional"
            // ? "Professional Membership Plan"
            // : props.description === "Community"
            // ? "Community Membership Plan"
            // : props.description === "Corporate"
            // ? "Corporate Membership Plan"
            // : "Invalid Plan",
  //       isTestMode: false,
  //       onComplete: function (response) {
  //         if (response.paymentStatus == "OVERPAID") {
  //           notify(
  //             "You current payment has exceeded the amount. The excess amount will be refunded within 24 hours"
  //           );
  //           return setTimeout(() => (window.location.pathname = "/"), 3000);
  //         }
  //         if (response.paymentStatus === "PAID") {
  //           // //console.log(response)
  //           return setTimeout(
  //             () => (window.location.pathname = "/team_reg"),
  //             9000
  //           );
  //         }
  //         if (response.paymentStatus == "PENDING") {
  //           notify("Payment Pending");
  //           return setTimeout(() => (window.location.pathname = "/"), 9000);
  //         }
  //       },
  //       onClose: function (data) {
  //         return setTimeout(() => notify("Payment Pending"), 9000);
  //       },
  //     });
  //   } catch (error) {
  //     //console.log("Failed to initailize payment" + error);
  //   }
  // };

  const requestForPayref = (e) => {
    e.preventDefault();
    setFormState({
      ...state,
      isLoading: true,
    });
    const paymentdescription = localStorage.getItem("paymentdesc");
    const paymentdes = paymentdescription ? JSON.parse(paymentdescription) : "";
    //console.log(paymentdes);
    const availableToken = localStorage.getItem("userToken");
    const token = availableToken
      ? JSON.parse(availableToken)
      : props.history.push("/signin");
    axios
      .get(`${API}/monnifypaymentreference`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => {
        //console.log(response);
        setFormState({
          ...state,
          user: response?.data[0]?.payment_reference,
          isLoading: false,
        });
        setTimeout(() => {
          payWithMonnify(response?.data[0]?.payment_reference);
        }, 1000);
      })
      .catch((error) => {
        //console.log(error);
        setFormState({
          ...state,
          isLoading: false,
        });
      });
  };
  const MakeTestPayment = (e) => {
    e.preventDefault();
    setFormState({
      ...state,
      isLoading: true,
    });
    const availableToken = localStorage.getItem("userToken");
    const token = availableToken
      ? JSON.parse(availableToken)
      : props.history.push("/signin");
    axios
      .get(`${API}/make-payment`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => {
        //console.log(response);
        setFormState({
          ...state,
          isLoading: false,
        });
        setTimeout(() => {
          props.history.push("/team_reg");
        }, 3000);
        notify("Payment Successfull");
      })
      .catch((error) => {
        //console.log(error);
        setFormState({
          ...state,
          isLoading: false,
        });
      });
  };

  return (
    <>
      <Container fluid={true}>
        <Row className="jcenter text-center payw1">
          <Col>
            <div className="signwa">Education Reform & Innovation Team Foundation</div>
          </Col>
        </Row>
        <Row className="jcenter">
          <Col md={5} className="paywrap1">
            {errorMessage && (
              <Alert key={2} variant="danger">
                {errorMessage}
              </Alert>
            )}
            <Form>
              <Row>
                <Col md={6}>
                  <Form.Group controlId="formBasicfirstname">
                    <label className="fstname">First Name</label>
                    <Form.Control
                      type="text"
                      name="first_name"
                      className="field1 notallowed"
                      value={first_name}
                      // onChange={changeActionOnFormData}
                      placeholder=""
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="formBasicPassword">
                    <label className="fstname">Last Name</label>
                    <Form.Control
                      type="text"
                      name="last_name"
                      className="field1 notallowed"
                      value={last_name}
                      // onChange={changeActionOnFormData}/
                      placeholder=""
                    />
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Group controlId="formBasicEmail">
                    <label className="fstname">Email</label>
                    <Form.Control
                      type="email"
                      name="email"
                      className="field1 notallowed"
                      value={email}
                      // onChange={changeActionOnFormData}
                      placeholder="Email Address"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  {" "}
                  <label className="fstname">Amount to charge</label>
                </Col>
                <Col md={3}>
                  <Form.Group controlId="formBasicfirstname">
                    <Form.Control
                      type="text"
                      name="first_name"
                      className="field1 notallowed"
                      value={"NGN"}
                      // onChange={changeActionOnFormData}
                      placeholder="NGN"
                    />
                  </Form.Group>
                </Col>
                <Col md={9}>
                  <Form.Group controlId="formBasicPassword">
                    <Form.Control
                      type="text"
                      name="last_name"
                      className="field1 notallowed"
                      value={amount}
                      // onChange={changeActionOnFormData}
                      placeholder=""
                    />
                  </Form.Group>
                </Col>
              </Row>
              <div className="text-center">
                <Button
                  variant="primary"
                  onClick={requestForPayref}
                  className="subbtn grb"
                  type="submit"
                >
                  {!isLoading ? "Make Payment" : "Processing"}
                </Button>
              </div>
              <ToastContainer
                enableMultiContainer
                containerId={"a"}
                toastClassName="bg-info text-white"
                hideProgressBar={true}
                position={toast.POSITION.TOP_CENTER}
              />
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
});

export default EritPaymentSummary;

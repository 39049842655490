import * as React from "react";
import { Link } from "react-router-dom";
import avatar from "../../../assets/avatar.svg";
import Axios from "axios";
import { API } from "../../../config";
import { useEffect, useState } from "react";

export interface IAppProps {
  Logout?: Function | any;
}

export function NavIsLoggedIn(props: IAppProps | any) {
  const [state, setState] = useState({ canseeDashboard: false });
  useEffect(() => {
    getCurrentAssessmentPosition();
  }, []);
  const getCurrentAssessmentPosition = (): void => {
    const availableToken = localStorage.getItem("userToken");
    const token: string = availableToken
      ? JSON.parse(availableToken)
      : "";
    Axios.get(`${API}/erit-progress`, {
      headers: { Authorization: `Token ${token}` },
    })
      .then((response) => {
        //console.log(response);
        if (
          response.data[0].payment === true ||
          response.data[0].onboarding === true
        ) {
          setState({
            canseeDashboard: true,
          });
        }
      })
      .catch((err) => {
        //console.log(err);
      });
  };
  return (
    <React.Fragment>
      <div className="title1">
        {state.canseeDashboard && (
          <Link to="/overview">
            <button className="title_ll title_ll2">Dashboard</button>
          </Link>
        )}
        
        <button onClick={props.Logout} className="title_ll">
          Log out
        </button>
      </div>
      <div className="title1">
        <span
          className="useravatarwraper"
          onClick={getCurrentAssessmentPosition}
        >
          <img src={avatar} className="useravatar" alt="avatar" />
        </span>
      </div>
    </React.Fragment>
  );
}

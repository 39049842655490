import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import "./style.css";
import onboarding7 from "../../assets/onboarding7.svg";
import useEffect from "react";
import Axios, { AxiosResponse } from "axios";
import { API } from "../../config";
import Navbar from "../Home/HomeComponents/navbar";
import Footer from "../Home/HomeComponents/footer";

const IndividualDashboardModal = () => {
  const [state, setState] = useState({
    isOpen: true,
    errorMessage: "",
    isLoading: false,
    first_name: "",
    last_name: "",
  });
  const closeModal = () => {
    setState({
      ...state,
      isOpen: true,
    });
  };
  const { first_name, last_name } = state;
  React.useEffect(() => {
    const availableToken = localStorage.getItem("userToken");
    const token = availableToken
      ? JSON.parse(availableToken)
      : "";
    const data = {};
    Axios.get<any, AxiosResponse<any>>(`${API}/dashboard/profile`, {
      headers: { Authorization: `Token ${token}` },
    })
      .then((response) => {
        //console.log(response);
        if (response.status === 200) {
          setState({
            ...state,
            ...response.data,
          });
        }
      })
      .catch((error) => {
        //console.log(error.response);
        if (error && error.response && error.response.data) {
          setState({
            ...state,
            errorMessage: error.response.data[0].message,
            isLoading: false,
          });
        }
        setState({
          ...state,
          errorMessage: "failed",
          isLoading: false,
        });
      });
  },[]);
  return (
    <div>
      <Navbar />
      <Modal show={state.isOpen} centered={true} onHide={closeModal} className="onbdash">
        <Modal.Body>
          <div className="text-center"> </div>
          <div className="skip dashskip">
            <Link to="/overview">Skip</Link>
          </div>
          <div className="welcome dashwelcome">
            {" "}
            Welcome{" "}
            <span className="user_name">
              {first_name} {last_name}
            </span>{" "}
            to the <span className="user_name">ERIT</span> experience!
          </div>
          <div className="intro">
            {/* This Assessment is the start to your route of self discovery */}
          </div>
          <div className="image whyimage">
            <img
              className="build_img whyimg"
              src={onboarding7}
              alt="start building"
            />
          </div>
          <div className="build whyassess">Why Take the Assessment?</div>
          <div className="whytake">
            <div className="descrip whydescrip">
              Part of being a Reformer is having a great grasp of your
              Competencies and Personality Dynamics. Your level of
              Self-awareness is key to facilitating a lasting change in the
              lives of the learner's and stakeholders in the education sector.
            </div>
            <div className="descrip whydescrip">
              The purpose of this assessment is to help you.
            </div>
            <div className="descrip whydescrip">
              Have a better grasp of your leadership and workplace competencies.
            </div>
            <div className="descrip whydescrip">
              Understand your strengths and weaknesses.
            </div>
            <div className="descrip whydescrip">
              Articulate your professional advantage and how to leverage it for
              career positioning.
            </div>
            <div className="descrip whydescrip">
              Identify areas for your growth and professional development.
            </div>
            <div className="descrip whydescrip">
              This assessment also helps ERIT pair you to impact project roles
              where you can do the most productive work.
            </div>
            <div className="descrip whydescrip">
              Treat this assessment as a self discovery exercise and answer all
              questions reflectively. You can finish in one sitting or save and
              continue your progress.
            </div>
          </div>
          <div className="text-center">
            <div className="next continue">
              <Link to="/assessmentphaseone">Continue</Link>
            </div>
            <div className="next continue bacm1">
              <a href="/overview">Opt Out</a>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* <Footer /> */}
    </div>
  );
};

export default IndividualDashboardModal;

import * as React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../kegennidashboard.css";
import avatar from "../../../assets/avatar.svg";
import Axios, { AxiosResponse } from "axios";
import { API } from "../../../config";
import firstlogo from "../../../assets/image 1.png";
import Button from "react-bootstrap/Button";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Form from "react-bootstrap/Form";
import AdminSideBar from "./AdminSideBar";
import AdminNavBar from "./AdminNavBar";
import DashboardLargeScreenNav from "../DashboardLargeScreenNav";
import whatsapp from "../../../assets/whatsapp.png";

interface State {
  complain: string;
  issue: string;
  successMsg: boolean;
  errorMessage: string;
  isLoading: boolean;
  showWarning: boolean;
  width: number;
}
class AdminSupport extends React.Component {
  state: State = {
    complain: "",
    issue: "",
    errorMessage: "",
    successMsg: false,
    isLoading: false,
    showWarning: false,
    width: 100,
  };
  submitForm = (e) => {
    e.preventDefault();
    const availableToken = localStorage.getItem("userToken");
    const token = availableToken ? JSON.parse(availableToken) : "";
    const data = {
      issue_category: this.state.issue,
      complain: this.state.complain,
    };
    Axios.post<any, AxiosResponse<any>>(`${API}/admin/support`, data, {
      headers: { Authorization: `Token ${token}` },
    })
      .then((res) => {
        //console.log(res.data);
        this.notify("Successful");
      })
      .catch((err) => {
        if (err) {
          //console.log(err);
          this.notify("Failed to send");
        }
      });
  };
  notify = (message: string) => toast(message, { containerId: "B" });
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  render() {
    const { complain, issue, isLoading, width } = this.state;
    return (
      <>
        <Container fluid={true} className="contann122">
          <AdminNavBar support={true}/>
          <Row>
            <AdminSideBar support={true} />
            <Col md={10} sm={12} className="prm">
              <DashboardLargeScreenNav title={"Support"} />
              <Row>
                <Col md={12} className="kisls">
                  <div className="kdashheader npps">
                    <div className=""></div>
                    <div>
                      <hr />
                    </div>
                    <Col md={12} className="paddn1">
                      <div className="whatdoudo offpad">
                        <div className="worddd">Drop your message </div>
                      </div>
                      <Row>
                        <Col md={6}>
                          <div className="Complain loop11">Issue category </div>
                          <Form.Control
                            as="select"
                            className="fmc jobr subhyt"
                            name="issue"
                            value={issue}
                            onChange={this.handleChange}
                            placeholder="Select Industry"
                          >
                            <option></option>
                            <option value="Payment">Payment</option>
                            <option value="Appointment">Appointment</option>
                            <option value="Resource center">Resource center</option>
                            <option value="others">Others</option>
                          </Form.Control>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <div className="Complain loop11">Complain </div>
                          <textarea
                            name="complain"
                            value={complain}
                            onChange={this.handleChange}
                            className="form-control jobr subhyt"
                            placeholder=""
                          />
                        </Col>
                      </Row>
                      <Row className="subsbs">
                        <Col md={12}>
                          <Button
                            className="retaketest"
                            onClick={this.submitForm}
                          >
                            Submit
                          </Button>
                        </Col>
                      </Row>
                      <Row className="subsbs">
                        <Col md={12}>
                        <div className="worddd">OR</div>
                        </Col>
                      </Row>
                      <Row className="subsbs">
                        <Col md={12}>
                          <a href="whatsapp://send?phone=+2348176100160 &text=Hello">
                            <Button className="whatsapp-btn"> Send us a DM
                          <img src={whatsapp} className="whatsapp-support" alt="whatsapp" />
                          </Button>
                          </a>
                        </Col>
                      </Row>
                    </Col>
                  </div>
                </Col>
              </Row>
            </Col>
            <ToastContainer
              enableMultiContainer
              containerId={"B"}
              toastClassName="bg-info text-white"
              hideProgressBar={true}
              position={toast.POSITION.TOP_CENTER}
            />
          </Row>
        </Container>
      </>
    );
  }
}
export default AdminSupport;

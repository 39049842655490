import * as React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./../kegennidashboard.css";
import DashboardLargeScreenNav from "../DashboardLargeScreenNav";
import { Button, Modal } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import AdminSideBar from "./AdminSideBar";
import AdminNavBar from "./AdminNavBar";
import { PieChart } from "react-minimal-pie-chart";
import { useEffect, useState } from "react";
import axios from "axios";
import { API } from "../../../config";

const AdminOverview = withRouter((props: any) => {
  const [state, setState] = useState<any>({ admin: [], pieChartData: {}, last_name: "", first_name: ""});
  const [modState, setModState] = useState<any>({
    confirm: false,
    confirmScreen: 0,
  });
  const { confirm, confirmScreen } = modState;
  const { admin, pieChartData, last_name, first_name } = state;
  const closeConfirmationModal = () => {
    setModState({
      ...modState,
      confirm: false,
    })
  };
  const openSelectedSummary = (value: any) => {
    switch (value) {
      case 1:
        setModState({
          ...modState,
          confirmScreen: 1,
          confirm: true,
        });
      break;
      case 2:
        setModState({
          ...modState,
          confirmScreen: 2,
          confirm: true,
        });
      break;
      case 3:
        setModState({
          ...modState,
          confirmScreen: 3,
          confirm: true,
        });
      break;
      case 4:
        setModState({
          ...modState,
          confirmScreen: 4,
          confirm: true,
        });
      break;
      case 5:
        setModState({
          ...modState,
          confirmScreen: 5,
          confirm: true,
        });
      break;
      case 6:
        setModState({
          ...modState,
          confirmScreen: 6,
          confirm: true,
        });
      break;
      default:
        setModState({
          ...modState,
          confirmScreen: 0,
          confirm: false,
        });
    };
  };
  const redirectToSelectedSummary = () => {
    if(confirmScreen === 1) {
      return window.location.assign("/adminteammembers");
    }
    else if(confirmScreen === 2) {
      return window.location.assign("/admin/overview/totalcompletedprofile");
    }
    else if(confirmScreen === 3) {
      return window.location.assign("/admin/overview/totalsuccessfulpayments");
    }
    else if(confirmScreen === 4) {
      return window.location.assign("/admin/overview/totalcommunitymembers");
    }
    else if(confirmScreen === 5) {
      return window.location.assign("/admin/overview/totalprofessionalmembers");
    }
    else if(confirmScreen === 6) {
      return window.location.assign("/admin/overview/totalcorporatemembers");
    }
  };
  useEffect(() => {
    const availableToken = localStorage.getItem("userToken");
    const token = availableToken
      ? JSON.parse(availableToken)
      : window.location.assign("/admin/signin");
    axios
      .all([
        axios.get(`${API}/admin/thematic-area-plot`, {
          headers: { Authorization: `Token ${token}` },
        }),
        axios.get(`${API}/admin/overview`, {
          headers: { Authorization: `Token ${token}` },
        }),
        axios.get(`${API}/dashboard/profile`, {
          headers: { Authorization: `Token ${token}` },
        }),
      ])
      .then(
        axios.spread((res1, res2, res3) => {
          setState({
            ...state,
            pieChartData: res1.data,
            admin: res2.data,
            first_name: res3.data.first_name,
            last_name: res3.data.last_name
          });
          //console.log(res1);
          //console.log(res2);
        })
      )
      .catch((err) => {
        //console.log(err);
      });
  }, []);
  //console.log(pieChartData);
  // //console.log(admin);
  return (
    <>
    <Container fluid={true} className="contann122">
      <AdminNavBar overview={true} />
      <Row>
        <AdminSideBar overview={true} />
        <Col md={10} sm={12} className="prm newprm">
          <DashboardLargeScreenNav title="Overview" />
          <Row>
            <Col md={12} className="kisls">
              <div className="kdashheader npps">
                <div className="fjss">
                  <div>
                    {" "}
                    <span className="kdashheaderlight idds">
                      Hi <span className="ksname">{first_name ? first_name + " " + last_name : ""}</span>
                    </span>
                  </div>
                </div>
              </div>
              <div className="messageerit findbelow">
                Find below the summary, chart and overview of your Team
                Members
              </div>

              <div>
                <div className="wwrap">
                  <div className="fourinfo pdbmmt">
                    <div className="firstoffour totals" onClick={() => openSelectedSummary(1)}>
                      <div className="fouri1"></div>
                      <div className="fouri1a">
                        <div className="mmber">Total SignUp</div>
                        <div className="mmber1">{admin.total_signups}</div>
                      </div>
                    </div>
                    <div className="firstoffour totals" onClick={() => openSelectedSummary(2)}>
                      <div className="fouri1"></div>
                      <div className="fouri1a">
                        <div className="mmber">Total Profile Completed</div>
                        <div className="mmber1">
                          {admin.total_profiles_completed}
                        </div>
                      </div>
                    </div>
                    <div className="firstoffour totals" onClick={() => openSelectedSummary(3)}>
                      <div className="fouri1"></div>
                      <div className="fouri1a">
                        <div className="mmber">Total Successful Payments</div>
                        <div className="mmber1">
                          {admin.total_successful_payments}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="wwrap other">
                  <div className="fourinfo">
                    <div className="firstoffour totals" onClick={() => openSelectedSummary(4)}>
                      <div className="fouri1"></div>
                      <div className="fouri1a">
                        <div className="mmber">Total Community Members</div>
                        <div className="mmber1">
                          {admin.total_associate_members}
                        </div>
                      </div>
                    </div>
                    <div className="firstoffour totals" onClick={() => openSelectedSummary(5)}>
                      <div className="fouri1"></div>
                      <div className="fouri1a">
                        <div className="mmber">Total Professional Members</div>
                        <div className="mmber1">
                          {admin.total_professional_members}
                        </div>
                      </div>
                    </div>
                    <div className="firstoffour totals" onClick={() => openSelectedSummary(6)}>
                      <div className="fouri1"></div>
                      <div className="fouri1a">
                        <div className="mmber">Total Corporate Members</div>
                        <div className="mmber1">
                          {admin.total_coporate_members}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="msgfrom">Members Thematic Area of Interest</div>
              <div className="flexa">
                <div className="diff">
                  <div className="piechrrt">
                    {Object.keys(pieChartData).length > 0 ? 
                    <PieChart
                      data={[
                        {
                          title: `${pieChartData.data[0].name} - ${pieChartData.data[0].amount}`,
                          value: pieChartData.data[0].value,
                          color: "#FFBC41",
                        },
                        {
                          title: `${pieChartData.data[1].name} - ${pieChartData.data[1].amount}`,
                          value: pieChartData.data[1].value,
                          color: "#202020",
                        },
                        {
                          title: `${pieChartData.data[2].name} - ${pieChartData.data[2].amount}`,
                          value: pieChartData.data[2].value,
                          color: "#3A405B",
                        },
                        {
                          title: `${pieChartData.data[3].name} - ${pieChartData.data[3].amount}`,
                          value: pieChartData.data[3].value,
                          color: "#3965FF",
                        },
                        {
                          title: `${pieChartData.data[4].name} - ${pieChartData.data[4].amount}`,
                          value: pieChartData.data[4].value,
                          color: "#F55F44",
                        },
                        {
                          title: `${pieChartData.data[5].name} - ${pieChartData.data[5].amount}`,
                          value: pieChartData.data[5].value,
                          color: "#26A85A",
                        },
                        {
                          title: `${pieChartData.data[6].name} - ${pieChartData.data[6].amount}`,
                          value: pieChartData.data[6].value,
                          color: "#5F235C",
                        },
                        {
                          title: `${pieChartData.data[7].name} - ${pieChartData.data[7].amount}`,
                          value: pieChartData.data[7].value,
                          color: "#5f9ea0",
                        },
                        {
                          title: `${pieChartData.data[8].name} - ${pieChartData.data[8].amount}`,
                          value: pieChartData.data[8].value,
                          color: "#a52a2a",
                        },
                      ]}
                    />
                    : '' }
                  </div>
                  <div className="pielabels">
                    <div className="eachlabel">
                      <span className="label1"></span>
                      <div className="pictxt">
                      School Leadership and Teacher Education
                      </div>
                    </div>
                    <div className="eachlabel">
                      <span className="label1 r2 "></span>
                      <div className="pictxt">Education Reform</div>
                    </div>
                    <div className="eachlabel">
                      <span className="label1 r3"></span>
                      <div className="pictxt">
                      Educational Technology and Infrastructure
                      </div>
                    </div>
                    <div className="eachlabel">
                      <span className="label1 r4"></span>
                      <div className="pictxt">Curriculum Review, Design and Development</div>
                    </div>
                    <div className="eachlabel">
                      <span className="label1 r5"></span>
                      <div className="pictxt">Media and Advocacy </div>
                    </div>
                    <div className="eachlabel">
                      <span className="label1 r6"></span>
                      <div className="pictxt">Research and Policy</div>
                    </div>
                    <div className="eachlabel">
                      <span className="label1 r7"></span>
                      <div className="pictxt">Education Funding & Innovation</div>
                    </div>
                    <div className="eachlabel">
                      <span className="label1 r8"></span>
                      <div className="pictxt">School Transformation (Primary)</div>
                    </div>
                    <div className="eachlabel">
                      <span className="label1 r9"></span>
                      <div className="pictxt">School Transformation (Secondary)</div>
                    </div>
                  </div>
                </div>
                <Link to="/adminteammembers" className="click">
                  <Button className="sendIV vwall">View Details</Button>
                </Link>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
    <Modal show={confirm} onHide={closeConfirmationModal} centered>
        <Modal.Body>
          <div className="exittxt">
            View the list of
            <span className="exittxtsub">
              {confirmScreen === 1 && " total signups?"}
              {confirmScreen === 2 && " members with completed profiles?"}
              {confirmScreen === 3 && " members with successful payments?"}
              {confirmScreen === 4 && " members on the community membership plan?"}
              {confirmScreen === 5 && " members on the professional membership plan?"}
              {confirmScreen === 6 && " members on the corporate membership plan?"}
            </span>
          </div>
          <div className="exitbtns">
            <button className="exitbtn1" onClick={closeConfirmationModal}>
              No
            </button>
            <button className="exitbtn2" onClick={redirectToSelectedSummary} >
              Yes
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
});

export default AdminOverview;

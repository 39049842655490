import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Link, withRouter } from 'react-router-dom';
import "./style.css";
import onboarding1 from "../../assets/jointeam.png";
import Axios, { AxiosResponse } from "axios";
import { API } from "../../config";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";

const JoinTeam =withRouter((props: any) => {
  const [state, setState] = useState({
    isOpen: true,
    shouldFillProfilesettings: true,
    shouldFillCvBuilder: false,
    errorMessage: "",
    isLoading: false,
    first_name: "",
    last_name: "",
    team: "",
  });
  const closeModal = () => {
    setState({
      ...state,
      isOpen: true,
    });
  };
  const onchange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };
  const { first_name, last_name, team } = state;
  React.useEffect(() => {
    const availableToken = localStorage.getItem("userToken");
    const token = availableToken ? JSON.parse(availableToken) : "";
    const data = {};
    Axios.get<any, AxiosResponse<any>>(`${API}/dashboard/profile`, {
      headers: { Authorization: `Token ${token}` },
    })
      .then((response) => {
        //console.log(response);
        if (response.status === 200) {
          setState({
            ...state,
            ...response.data,
          });
        }
      })
      .catch((error) => {
        //console.log(error.response);
        if (error && error.response && error.response.data) {
          setState({
            ...state,
            errorMessage: error.response.data[0].message,
            isLoading: false,
          });
        }
        setState({
          ...state,
          errorMessage: "failed",
          isLoading: false,
        });
      });
  }, []);
  const submitForm = () => {
    if(team==""){
      return notify("Please select a team")
    }
    const availableToken = localStorage.getItem("userToken");
    const token = availableToken ? JSON.parse(availableToken) : "";
    const data = {
      team,
    };
    Axios.post<any, AxiosResponse<any>>(`${API}/dashboard/team`, data, {
      headers: { Authorization: `Token ${token}` },
    })
      .then((response) => {
        //console.log(response);
        if (response.status === 200) {
          notify("Successfully added to team");
        }
      })
      .then(()=>{
        // window.open("https://calendly.com/erit-yudimy/30min");
        setTimeout(()=>{
          props.history.push("/individualdashboardonboarding")
        },1000)
      })
      // .then(()=>{
      //    setTimeout(()=>{
      //       props.history.push("/individualdashboardonboarding")
      //     },1000)
      // })
      .catch((error) => {
        //console.log(error.response);
        if (error && error.response && error.response.data) {
          setState({
            ...state,
            errorMessage: error.response.data[0].message,
            isLoading: false,
          });
        }
        setState({
          ...state,
          errorMessage: "failed",
          isLoading: false,
        });
      });
  };
  const notify = (message: string) => toast(message, { containerId: "i" });
  return (
    <div>
      <Modal show={state.isOpen} className="individualonb" centered={true} 
      // onHide={closeModal}
      >
        <Modal.Body>
          <div className="text-center"> </div>
          {/* <div className="skip">
            <Link to="/overview">Skip</Link>
          </div> */}
          <div className="welcome teamwlcm">
            {" "}
            Welcome{" "}
            <span className="user_name">
              {" "}
              {first_name} {last_name}
            </span>{" "}
            to the ERIT Member Portal
          </div>
          <div className="intro"> Select your preferred ERIT Reform Team</div>
          <div className="image">
            <img
              className="build_img1"
              src={onboarding1}
              alt="start building"
            />
          </div>
          <div className="slectea newslctarea"> Select a Team</div>
          <div className="reformt2 newreformt2">
            <label className="checkcontainerround">
              <div className="reformt">
                <span className="wrapradio1">
                  <input
                    type="radio"
                    name="team"
                    className="radio1"
                    id=""
                    value="1"
                    onChange={onchange}
                  />
                </span>
                Thought Leadership Team (TLT)
              </div>
            </label>
            <label className="checkcontainerround">
              <div className="reformt">
                <span className="wrapradio1">
                  <input
                    type="radio"
                    name="team"
                    className="radio1"
                    id=""
                    value="2"
                    onChange={onchange}
                  />
                </span>
                Project Execution Team (PET)
              </div>
            </label>
            <label className="checkcontainerround">
              <div className="reformt">
                <span className="wrapradio1">
                  <input
                    type="radio"
                    name="team"
                    className="radio1"
                    id=""
                    value="3"
                    onChange={onchange}
                  />
                </span>
                Both
              </div>
            </label>
          </div>
          <div>
            <div className="joint savecv" onClick={submitForm}>
              Apply
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <ToastContainer
        enableMultiContainer={false}
        containerId={"i"}
        toastClassName="bg-info text-white"
        hideProgressBar={true}
        position={toast.POSITION.TOP_CENTER}
      />
    </div>
  );
});

export default JoinTeam;

import * as React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./../kegennidashboard.css";
import DashboardLargeScreenNav from "../DashboardLargeScreenNav";
import { Link, withRouter } from "react-router-dom";
import AdminSideBar from "./AdminSideBar";
import AdminNavBar from "./AdminNavBar";
import { Button, Form, Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import axios from "axios";
import { API } from "../../../config";
import worklife from "../../../assets/worklife.svg";
import updownarrow from "../../../assets/updownarrow.png";
import file from "../../../assets/file.png";
import rightimg from "../../../assets/rightarrow.png";
import leftimg from "../../../assets/leftarrow1.png";
import professional4 from "../../../assets/professional4.png";
import downloadicon from "../../../assets/download.png";
import copy from "../../../assets/copy.png";
import logoutImage from "../../../assets/logout.png";
import { formatTime, checkIfIsOdd } from "./controller";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./adminModal.css";
import SideBarNewDashboard from "../SideBarNewDashboard";
import DashboardNav from "../DashboardNavBar";
import search from "../../../assets/search.png";

const eritTeam = ["Thought Leadership Team (TLT)", "Project Execution"];
const thematicAreas = [
  "Advisory Services",
  "Education & Reform Transformation",
  "Media & Advisory",
  "Research",
  "Training and Development",
  "Fund Raising ",
  "Education & Technology Delivery",
];
const Payment_plans = [
  " Community membership plan",
  "Coporate membership plan",
  "Professional membership plan",
];
const UserResourceCenter = withRouter((props: any) => {
  const [state, setState] = useState<any>({
    isOpen: false,
    isOpen2: false,
    isOpen3: false,
    labels: [],
    isloading: false,
    secondloading: false,
    next: "",
    previous: "",
    admin: [],
    founduser: {},
    ListOfTags: [],
    filter: "",
    heading: "",
    content: "",
    erit_team: [],
    searchResource: "",
    thematic_area: [],
    resource: [],
    categoryList: [],
    id: "",
    total_pages: "",
    newtag: "",
    first_name: "",
    last_name: "",
    currentPage: "",
    modalIsOpen: false,
    link: "",
    recipient: "",
    hascopiedLink: false,
    no_attach: "",
    photo_url: [],
    pdf_url: [],
    photos: [],
    payment_plan: [],
    other_files: [],
    searching: false,
  });

  const fetchByFilter = (keyword) => {
    const availableToken = localStorage.getItem("userToken");
    const token = availableToken
      ? JSON.parse(availableToken)
      : window.location.assign("/admin/signin");
    setState({
      ...state,
      searching: true,
    });
    axios
      .get(`${API}/individual/resource-list/?category=${keyword}`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((res2) => {
        //console.log(res2);
        setState({
          ...state,
          ...res2.data,
          admin: res2.data,
          resource: res2.data.results,
          searching: false,
        });
        //console.log(res2);
      })
      .catch((err) => {
        //console.log(err);
        setState({
          ...state,
          searching: false,
        });
      });
  };

  const notify = (message: string) => toast(message, { containerId: "B" });
  const [confirmState, setConfirmState] = React.useState({
    confirmModal: false,
  });

  const closeConfirmModal = () => {
    setConfirmState({
      ...confirmState,
      confirmModal: false,
    });
  };
  const openconfirmModal = () => {
    setConfirmState({
      ...confirmState,
      confirmModal: true,
    });
  };
  const { confirmModal } = confirmState;
  const closeModal3 = () => {
    setState({
      ...state,
      modalIsOpen: false,
    });
  };
  const searchForResource = (searchword) => {
    const availableToken = localStorage.getItem("userToken");
    const token = availableToken
      ? JSON.parse(availableToken)
      : window.location.assign("/admin/signin");
    setState({
      ...state,
      searching: true,
    });
    axios
      .get(`${API}/individual/resource-list/?q=${searchword}`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((res2) => {
        //console.log(res2);
        setState({
          ...state,
          ...res2.data,
          admin: res2.data,
          resource: res2.data.results,
          searching: false,
        });
        //console.log(res2);
      })
      .catch((err) => {
        //console.log(err);
        setState({
          ...state,
          searching: false,
        });
      });
  };
  const onchange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
    if (e.target.name == "filter") {
      fetchByFilter(e.target.value);
    }
  };
  const openModal3 = (item) => {
    resource.forEach((data) => {
      if (data.content == item) {
        setState({
          ...state,
          ...data,
          id: data.id,
          modalIsOpen: true,
          erit_team: data.erit_team,
          thematic_area: data.thematic_area,
          other_files: data.other_files,
          photos: data.photos,
        });
      }
    });
  };
  const onInputChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };
  useEffect(() => {
    const availableToken = localStorage.getItem("userToken");
    const token = availableToken
      ? JSON.parse(availableToken)
      : window.location.assign("/signin");
    axios
      .all([
        axios.get(`${API}/admin/thematic-area-plot`, {
          headers: { Authorization: `Token ${token}` },
        }),
        axios.get(`${API}/individual/resource-list/`, {
          headers: { Authorization: `Token ${token}` },
        }),
        axios.get(`${API}/dashboard/profile`, {
          headers: { Authorization: `Token ${token}` },
        }),
        axios.get(`${API}/resource-categories/`, {
          headers: { Authorization: `Token ${token}` },
        }),
      ])
      .then(
        axios.spread((res1, res2, res3, res4) => {
          //console.log(res2);
          setState({
            ...state,
            ...res2.data,
            categoryList: [...res4.data],
            pieChartData: res1.data,
            admin: res2.data,
            resource: res2.data.results,
            first_name: res3.data.first_name,
            last_name: res3.data.last_name,
          });
          //console.log(res1);
          //console.log(res2);
        })
      )
      .catch((err) => {
        //console.log(err);
      });
  }, []);
  const nextPage = () => {
    const availableToken = localStorage.getItem("userToken");
    const token = availableToken
      ? JSON.parse(availableToken)
      : window.location.assign("/admin/signin");
    axios
      .all([
        axios.get(`${next}`, {
          headers: { Authorization: `Token ${token}` },
        }),
      ])
      .then(
        axios.spread((res2) => {
          //console.log(res2);
          window.scrollTo(-0, -0);
          setState({
            ...state,
            ...res2.data,
            admin: res2.data,
            resource: res2.data.results,
          });
          //console.log(res2);
        })
      )
      .catch((err) => {
        //console.log(err);
      });
  };
  const prevPage = () => {
    const availableToken = localStorage.getItem("userToken");
    const token = availableToken
      ? JSON.parse(availableToken)
      : window.location.assign("/admin/signin");
    axios
      .all([
        axios.get(`${previous}`, {
          headers: { Authorization: `Token ${token}` },
        }),
      ])
      .then(
        axios.spread((res2) => {
          //console.log(res2);
          window.scrollTo(-0, -0);
          setState({
            ...state,
            ...res2.data,
            admin: res2.data,
            resource: res2.data.results,
          });
          //console.log(res2);
        })
      )
      .catch((err) => {
        //console.log(err);
      });
  };
  const changeCopiedState = () => {
    setState({
      ...state,
      hascopiedLink: true,
    });
    setTimeout(() => {
      setState({
        ...state,
        hascopiedLink: false,
      });
    }, 3000);
  };
  const {
    first_name,
    last_name,
    isloading,
    modalIsOpen,
    total_pages,
    link,
    page,
    searching,
    hascopiedLink,
    content,
    next,
    previous,
    heading,
    erit_team,
    other_files,
    categoryList,
    thematic_area,
    pdf_url,
    photo_url,
    filter,
    photos,
    searchResource,
    id,
    resource,
  } = state;
  //console.log(photos);
  //console.log(other_files);
  return (
    <Container fluid={true} className="contann122">
      <DashboardNav overview={true} />
      <Row>
        <SideBarNewDashboard resource={true} />
        <Col md={10} sm={12} className="prm">
          <DashboardLargeScreenNav title="Resource Center" />
          <Row>
            <Col md={12} className="kisls">
              <div className="kdashheader npps">
                <div className="fjss">
                  <div>
                    {" "}
                    <span className="kdashheaderlight idds">
                      Hi{" "}
                      <span className="ksname">
                        {first_name ? first_name + " " + last_name : ""}
                      </span>
                    </span>
                  </div>
                  <div className="ffrr1">
                    <img src={search} className="searchi ssarh" alt="search" />
                    <input
                      placeholder="Search resource name"
                      type="text"
                      value={searchResource}
                      name={"searchResource"}
                      onChange={onchange}
                      onKeyPress={(e: any) => {
                        if (e.key === "Enter") {
                          searchForResource(e.target.value);
                        }
                      }}
                      className="searchinput form-control"
                    />
                  </div>
                </div>
              </div>
              <div className="messageerit findbelow">
                List of all Resources currently shared with you
              </div>
              {/* {resource.length === 0 && (
                <>
                  <div className="bokdness">
                    <img src={worklife} className="worklife" alt="worklife" />
                  </div>
                  <div className="booknees">
                    You currently have no shared resource
                  </div>
                </>
              )} */}
              <div className="wraptanll">
                <div className="crrr1 hideinsmall">
                  <div className="crrr1a animated fadeInDown">Heading</div>
                  <div className="crrr1b animated fadeInDown">
                    Attached file
                  </div>
                  <div className="crrr1c animated fadeInDown">Date Created</div>
                  <div
                    className="crrr1d animated fadeInDown"
                    onClick={() => {
                      setState({
                        ...state,
                        resource: resource.reverse(),
                      });
                    }}
                  >
                    Sort
                    <img src={updownarrow} className="updownarrow" />{" "}
                  </div>
                  <div>
                    {/* <Form.Control
                        as={"select"}
                        onChange={onchange}
                        value={filter}
                        className={"fmcaaa linkert linkertq"}
                        name="filter"
                        placeholder=""
                      >
                        {categoryList.map((data, i) => (
                          <option key={i} value={data.id}>{data.name}</option>
                        ))}
                      </Form.Control> */}
                  </div>
                </div>
                {resource.map((data, i) => (
                  <div
                    className={
                      !checkIfIsOdd(i)
                        ? "crrr2 crrr1 bg-white"
                        : "crrr2 crrr1 crgray"
                    }
                    key={i}
                  >
                    <div className="crrr1a">
                      <div className="smallscreenonly animated fadeInDown">
                        Heading
                      </div>
                      {data?.heading}
                    </div>
                    <div className="crrr1b animated fadeInDown">
                      <div className="smallscreenonly">Attached file</div>
                      <img src={file} /> {data?.no_attach}
                    </div>
                    <div className="crrr1c animated fadeInDown">
                      <div className="smallscreenonly">Date Created</div>
                      {formatTime(data.date_created)}
                    </div>
                    <div className="crrr1d animated fadeInDown">
                      <Button
                        className="winebtn1"
                        onClick={() => openModal3(data.content)}
                      >
                        View
                      </Button>
                    </div>
                    <div className="crrr1cc animated fadeInDown"></div>
                  </div>
                ))}
                {/* <div className="crrr2 crrr1 crgray">
                  <div className="crrr1a">End of year Achievement</div>
                  <div className="crrr1b">
                    <img src={file} /> 2
                  </div>
                  <div className="crrr1c">22nd April, 2020</div>
                  <div className="crrr1d">
                    <Button className="winebtn1">View</Button>
                  </div>
                </div> */}
              </div>
              {resource.length > 0 && (
                <div className="dspl widdjt">
                  <span>
                    Dispalying {page} out of {total_pages}
                  </span>
                  <div>
                    {previous && (
                      <img
                        src={leftimg}
                        onClick={prevPage}
                        className="leftimg"
                        alt="leftimg"
                      />
                    )}
                    {next && (
                      <img
                        src={rightimg}
                        onClick={nextPage}
                        className="rightimg"
                        alt="leftimg"
                      />
                    )}
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      <Modal
        show={modalIsOpen}
        centered={true}
        className="erritmodal"
        onHide={closeModal3}
      >
        <div className="closeselc2a">
          <span className="closeselc2b" onClick={closeModal3}>
            &times;
          </span>
        </div>
        <Modal.Body className="profl">
          <div className="modalnod">{heading}</div>
          <div className="fgrnbga"></div>
          <div className="mauris">{content}</div>
          {photos[0] && (
            <>
              <div className="Uploads1">Image Uploads</div>
              <div className="fix3images">
                {photos?.map((data, i) => (
                  <div className="eritimg1" key={i}>
                    <img className="professional4" src={data.url} />
                    <div className="tht12">
                      <a href={data} target="blank">
                        <img
                          src={downloadicon}
                          className="downloadicon"
                          alt=""
                        />
                      </a>
                    </div>
                  </div>
                ))}
                {/* <div className="eritimg1">
                  <img className="professional4" src={professional4} />
                  <div className="tht12">
                    <img src={downloadicon} className="downloadicon" alt="" />
                  </div>
                </div>
                <div className="eritimg1">
                  <img className="professional4" src={professional4} />
                  <div className="tht12">
                    <img src={downloadicon} className="downloadicon" alt="" />
                  </div>
                </div> */}
              </div>
            </>
          )}
          {other_files.length > 0 && (
            <div className="ddsfl">
              <div className="Uploads1">Attachments</div>
              <div className="addz">
                {other_files.map((data, i) => (
                  <a href={data?.url} target={"blank"} key={i}>
                    <span
                      className="addz23 addzxx"
                      key={i}
                      title={data.name.split("/")[1]}
                    >
                      {/* file {[parseInt(1 + i)]} &darr; */}
                      {data.name.split("/")[1]}
                    </span>
                  </a>
                ))}
              </div>
            </div>
          )}
          <Row className="polx">
            <Col md={12} className="eachfield">
              <Form.Group>
                <a href={link} target="blank">
                  <Form.Control
                    type="text"
                    required
                    value={link}
                    className={"fmca linkert"}
                    name="link"
                    placeholder=""
                  />
                </a>
                <div className="spna1a2">
                  <span className="spna122">Links</span>
                  <span className="spna122a">
                    <img
                      src={copy}
                      className="copy"
                      onClick={() => {
                        navigator.clipboard.writeText(link);
                        changeCopiedState();
                      }}
                      alt=""
                    />
                    {hascopiedLink && (
                      <span className="coppd animated fadeInLeft">Copied!</span>
                    )}
                  </span>
                </div>
              </Form.Group>
            </Col>
            {/* <Col md={12} className="eachfield2">
              <Form.Group>
                <div onChange={onInputChange} className={"fmca"}>
                  <div className="addz">
                    {erit_team.map((data, i) => (
                      <span className="addz23" key={i}>
                        {eritTeam[data]}
                      </span>
                    ))}
                  </div>
                  <div className="addz">
                    {thematic_area.map((data, i) => (
                      <span className="addz23" key={i}>
                        {thematicAreas[data]}
                      </span>
                    ))}
                  </div>
                </div>
                <div className="textad">
                  <span className="spna122">Recipient</span>
                </div>
              </Form.Group>
            </Col> */}
          </Row>
          <Row className="bottombtn1">
            <Col className="bottombtn">
              <div className="fgrnbga" onClick={closeModal3}>
                <Button className="fgreen">Close</Button>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>{" "}
      <Modal
        show={confirmModal}
        className="warning22e"
        centered={true}
        onHide={closeConfirmModal}
      >
        <Modal.Body>
          <div className="text-center">
            {" "}
            <img src={logoutImage} className="popUUp" alt="failedNotice" />{" "}
          </div>
          <div className="areusure1">
            are you sure you want to <b> remove this resource?</b>
          </div>
          <div className="text-center planupgrade">
            <div
              className="retaketest upss1 planupgradebtn mddd1"
              onClick={closeConfirmModal}
            >
              Go Back
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </Container>
  );
});

export default UserResourceCenter;

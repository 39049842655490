import * as React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./kegennidashboard.css";
import DashboardLargeScreenNav from "./DashboardLargeScreenNav";
import { Button } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import DashboardNav from "./DashboardNavBar";
import HorizontalBar from "./HorizontalBar";
import noplan from "../../assets/commingsoon.png";
import SideBarNewDashboard from "./SideBarNewDashboard";

const ComingSoon = withRouter((props: any) => {
  return (
    <Container fluid={true} className="contann122">
      <DashboardNav />
      <Row>
      <SideBarNewDashboard/>
        <Col md={10} sm={12} className="prm">
          <DashboardLargeScreenNav title="" />
          <Row>
            <Col md={12} className="kisls">
              <div className="norec pad23">
                <img
                  src={noplan}
                  className="norecommendations cooming"
                  alt="norecommendations"
                />
                <div className="udont frtr2">This feature is coming soon</div>
                <div className="udont ">
                  {" "}
                  <span className="takeassesment12 ftre">
                      We are working tirelesly to give you the best Experience{" "}
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
});

export default ComingSoon;

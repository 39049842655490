import * as React from "react";
import SideNav from "react-simple-sidenav";
import Row from "react-bootstrap/Row";
import imgCart from "../../assets/eritlogo.png";
import Col from "react-bootstrap/Col";
import activeinsight from "../../assets/insights_active.png";
import Overview_inactive from "../../assets/Overview_inactive.png";
import chatinactive from "../../assets/Chat with a counsellor_inactive.png";
import chatactive from "../../assets/Chat with a counsellor_active.png";
import inactiveinsight from "../../assets/inactiveinsight.png";
import recommedationactive from "../../assets/Counsellors recommendation_active.png";
import recommedationinactive from "../../assets/Counsellors recommendation_inactive.png";
import jobrecommedationactive from "../../assets/Job recommendation_active.png";
import jobrecommedationinactive from "../../assets/Job recommendation_inactive.png";
import settingsactive from "../../assets/settings.png";
import settingsinactive from "../../assets/Settings_inactive.png";
import subscriptionactive from "../../assets/Subcription_active.png";
import subscriptioninactive from "../../assets/Subscription_inactive.png";
import profilebuilder from "../../assets/Profile builder_active.png";
import profilebuilderinactive from "../../assets/Profile builder_inactive.png";
import support from "../../assets/Support_active.png";
import supportinactive from "../../assets/Support_inactive.png";
import overview from "../../assets/overview.png";
import logout from "../../assets/log-out.png";
import "../Home/Home/Home.css";
import { Link } from "react-router-dom";
import Axios, { AxiosResponse } from "axios";
import { API } from "../../config";
import { Modal } from "react-bootstrap";
import exit from "../../assets/exit.png";
import failedNotice from "../../assets/failedNotice.png";
import "./kegennidashboard.css";

const DashboardNav = (props: any) => {
  const [user, setNewState] = React.useState("");
  const [showNav, setShowNav]: any = React.useState(false);
  const [modState, setModState] = React.useState({
    logoutModal: false,
    confirmAssessment: false,
    checkPayment: false,
  });
  const [state, setState] = React.useState({
    shouldViewCareerInsight: false,
  });
  const { logoutModal, confirmAssessment, checkPayment } = modState;
  const { shouldViewCareerInsight } = state;
  const closePaymentCheck = () => {
    setModState({
      ...modState,
      checkPayment: false,
    });
  };
  const openPaymentCheck = () => {
    setModState({
      ...modState,
      checkPayment: true,
    });
  };
  const closeAssessmentCheck = () => {
    setModState({
      ...modState,
      confirmAssessment: false,
    });
  };
  const openAssessmentCheck = () => {
    setModState({
      ...modState,
      confirmAssessment: true,
    });
  };
  const closeLogoutModal = () => {
    setModState({
      ...modState,
      logoutModal: false,
    });
  };
  const openLogoutModal = () => {
    setModState({
      ...modState,
      logoutModal: true,
    });
  };
  // const logOutMobile = (e) => {
  //   e.preventDefault();
  //   const details: any = localStorage.getItem("userDetails");
  //   const info = JSON.parse(details);
  //   let token = info.token;
  //   Axios.get(`${API}/api/v1/none`, {
  //     headers: { Authorization: `Bearer ${token}` },
  //   })
  //     .then((res) => {
  //       window.location.pathname = "/";
  //       localStorage.clear();
  //     })
  //     .catch((err) => {
  //       //console.log(err);
  //     });
  // };
  const logOut = () => {
    localStorage.clear();
    window.location.assign("/signin");
  };
  React.useEffect(() => {
    const availableToken = localStorage.getItem("userToken");
    const token: string = availableToken
      ? JSON.parse(availableToken)
      : window.location.assign("/signin");
    Axios.get(`${API}/erit-progress`, {
      headers: { Authorization: `Token ${token}` },
    })
      .then((response) => {
        // //console.log(response.status);
        // let viewInsight = response.data[0].completed_assessment === true && response.data[0].payment === true;

        setState({
          ...state,
          shouldViewCareerInsight: response.data[0].completed_assessment,
        });

        // if (response.data[0].settings === false) {
        //   return window.location.assign("/dashboardsettings");
        // }
        // if (fillCvBuilder) {
        //   return props.history.push("/paymentsummary");
        // }
      })
      .catch((err) => {
        //console.log(err);
      });
  }, []);
  const checkIfUserHasMadePaymentForFullResult = () => {
    const availableToken = localStorage.getItem("userToken");
    const token = availableToken
      ? JSON.parse(availableToken)
      : window.location.assign("/signin");
    Axios.get<any, AxiosResponse<any>>(`${API}/paymentstatus`, {
      headers: { Authorization: `Token ${token}` },
    })
      .then((response) => {
        if (response?.data[0]?.free_membership_plan === false) {
          if (shouldViewCareerInsight === false) {
            return openAssessmentCheck();
          } else {
            return window.location.assign("/thirdpary/fullresult");
          }
        } else {
          openPaymentCheck();
        }
      })
      .catch((error) => {
      });
  };
  const checkIfUserHasMadePaymentForResourceCenter = () => {
    const availableToken = localStorage.getItem("userToken");
    const token = availableToken
      ? JSON.parse(availableToken)
      : window.location.assign("/signin");
    Axios.get<any, AxiosResponse<any>>(`${API}/paymentstatus`, {
      headers: { Authorization: `Token ${token}` },
    })
      .then((response) => {
        // console.log(response);
        // //console.log(props);
        if (response?.data[0]?.free_membership_plan === false) {
          return window.location.assign("/user_resource_center");
        }
        openPaymentCheck();
      })
      .catch((error) => {
        //console.log(error);
      });
  };
  return (
    <div>
      <Row>
        <div className="hnav">
          <img src={imgCart} className="imgCart" alt="imgCart" />
          <div className="hamburger" onClick={() => setShowNav(true)}>
            <div className="line2a"></div>
            <div className="line2a"></div>
            <div className="line2a"></div>
          </div>
        </div>
      </Row>
      <SideNav
        showNav={showNav}
        style={{ background: showNav ? "rgba(0, 0, 0, 0.7)" : "none" }}
        navStyle={{
          width: "70%",
          background: "#001833",
          padding: 0,
          margin: "0px",
        }}
        onHideNav={() => setShowNav(false)}
        openFromRight={true}
        titleStyle={{
          backgroundColor: "#fff",
          color: "#444444",
          paddingLeft: 0,
          paddingBottom: 0,
          paddingTop: 0,
          fontSize: 17,
          textAlign: "left",
        }}
        title={[,]}
        itemStyle={{
          backgroundColor: "#fff",
          padding: 0,
          margin: "0px",
          textAlign: "left",
        }}
        items={[
          <div className={"siddlemobile"}>
            <div className={"navitem1 fixed-mobilenav ffnewsize"}>
              <Link to={"/overview"}>
                <div className={props.overview ? "activegb" : "gbn"}>
                  <img
                    src={props.overview ? overview : Overview_inactive}
                    className="sideimage"
                    alt="sideimage"
                  />{" "}
                  Overview
                </div>
              </Link>
              {/* <Link to="/comingsoon">
                <div className={props.chat ? "activegb" : "gbn"}>
                  <img
                    src={props.chat ? chatactive : chatinactive}
                    className="sideimage"
                    alt="sideimage"
                  />
                  Messages
                </div>
              </Link> */}

              <Link to="/profilebuilder">
                <div className={props.builder ? "activegb" : "gbn"}>
                  <img
                    src={
                      props.builder ? profilebuilder : profilebuilderinactive
                    }
                    className="sideimage"
                    alt="sideimage"
                  />
                  Profile Builder
                </div>
              </Link>
              <div
                className={props.resource ? "activegb" : "gbn"}
                onClick={checkIfUserHasMadePaymentForResourceCenter}
              >
                <img
                  src={props.resource ? profilebuilder : profilebuilderinactive}
                  className="sideimage"
                  alt="sideimage"
                />
                Resource Center
              </div>
              <div
                onClick={checkIfUserHasMadePaymentForFullResult}
                className={props.insight ? "activegb" : "gbn"}
              >
                {" "}
                <img
                  src={props.insight ? activeinsight : inactiveinsight}
                  className="sideimage"
                  alt="sideimage"
                />
                Career Insight
              </div>
              <div className="divide_thro"></div>
              <Link to="/dashboardsettings">
                <div className={props.settings ? "activegb" : "gbn"}>
                  <img
                    src={props.settings ? settingsactive : settingsinactive}
                    className="sideimage"
                    alt="sideimage"
                  />
                  Settings
                </div>
              </Link>
              {/* <div className={props.councrec ? "activegb" : "gbn"}>
            {" "}
            <Link to="/counsellorsrecommendation">
              <img
                src={
                  props.councrec ? recommedationactive : recommedationinactive
                }
                className="sideimage"
                alt="sideimage"
              />
              Counsellors Recommendation
            </Link>
          </div> */}
              <Link to="/dashboardsubscriptionplan">
                <div className={props.subscription ? "activegb" : "gbn"}>
                  <img
                    src={
                      props.subscription
                        ? subscriptionactive
                        : subscriptioninactive
                    }
                    className="sideimage"
                    alt="sideimage"
                  />
                  Payment
                </div>
              </Link>
              <Link to="dashboardsupport">
                <div className={props.support ? "activegb" : "gbn"}>
                  <img
                    src={props.support ? support : supportinactive}
                    className="sideimage"
                    alt="sideimage"
                  />
                  Support
                </div>
              </Link>
              <div className={"gbn"} onClick={openLogoutModal}>
                <span>
                  <img src={logout} className="sideimage" alt="sideimage" />
                  Logout
                </span>
              </div>
            </div>
          </div>,
        ]}
      />
      <Modal show={logoutModal} onHide={closeLogoutModal} centered>
        <Modal.Body>
          <img className="exiticon" src={exit} alt="exit icon" />
          <div className="exittxt">
            Are you sure you want to <span className="exittxtsub">LogOut?</span>
          </div>
          <div className="exitbtns">
            <button className="exitbtn1" onClick={closeLogoutModal}>
              Go Back
            </button>
            <button className="exitbtn2" onClick={logOut}>
              Log Out
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={confirmAssessment} onHide={closeAssessmentCheck} centered>
        <Modal.Body>
          <div className="text-center">
            {" "}
            <img
              src={failedNotice}
              className="failedNotice"
              alt="failedNotice"
            />{" "}
          </div>
          <div className="nono"> Oh No! </div>
          <div className="nonosubtxt">
            You have to take the competency assessment to view your career
            insight
          </div>
          <div className="text-center">
            <button
              className="nonobtn"
              onClick={() => window.location.assign("/assessmentphaseone")}
            >
              Take Assessment
            </button>
          </div>
          <img />
        </Modal.Body>
      </Modal>
      <Modal show={checkPayment} onHide={closePaymentCheck} centered>
        <Modal.Body>
          <div className="text-center">
            {" "}
            <img
              src={failedNotice}
              className="failedNotice"
              alt="failedNotice"
            />{" "}
          </div>
          <div className="nono"> Oh No! </div>
          <div className="nonosubtxt">
            This package is not available on this plan <br /> Please Upgrade
            your Plan
          </div>
          <div className="text-center">
            <button
              className="nonobtn"
              onClick={() =>
                window.location.assign("/dashboardsubscriptionplan")
              }
            >
              View Current Plan
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default DashboardNav;

import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import "./style.css";
import onboarding6 from  "../../assets/onboarding6.svg";

const OrganizationOnboardingModal2 =()=>{
    const [state, setState] = useState({isOpen: true});
    const closeModal =()=> {
        setState({isOpen: true});
    }
    return (
        <div>
            <Modal
              show={state.isOpen}
              centered={true}
              onHide={closeModal}
            >
              <Modal.Body>
                <div className="text-center">
                  {" "}
                  {" "}
                </div>
                <div className="skip">
                    <Link to="/overview">Skip</Link>
                </div>
                <div className="welcome"> Welcome <span className="user_name" >OrganizationName</span> to the ERIT experience!</div>
                <div className="intro">
                  To get paired on reform projects, lets take you through the following simple steps
                </div>
                <div className="image">
                <img className="build_img" src={onboarding6} alt="start building"/>
                </div>
                <div className="build">Track Team Progress</div>
                <div className="descrip">Completing your assessments gives us an insight towards how we can help you reach your full potential</div>
                <div className="scroll">
                    <span className="circle">{" "}</span>
                    <span className="rect">{" "}</span>
                    <span className="circle">{" "}</span>
                    <span className="text-center">
                        <div className="next">
                            <Link to="/organizationonboarding3">Next</Link>
                        </div>
                        <div className="prev">
                            <Link to="/organizationonboarding1">Prev</Link>
                        </div> 
                    </span>
                </div>
              </Modal.Body>
            </Modal>
        </div>
    )
}

export default OrganizationOnboardingModal2;
import * as React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./../kegennidashboard.css";
import DashboardLargeScreenNav from "../DashboardLargeScreenNav";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import userimg from "../../../assets/userimg.png";
import newmsg from "../../../assets/newmsg.svg";
import searchicon from "../../../assets/searchicon.png";
import OrganzationSideBar from './OrganizationSideBar';
import OrganizationDashboardNavBar from './OrganizationDashboardNavBar';

const OrganizationDashboardInvite = () => {
  return (
    <Container fluid={true} className="contann122">
      <OrganizationDashboardNavBar overview={true} />
      <Row>
        <OrganzationSideBar overview={true} />
        <Col md={10} sm={12} className="prm">
          <DashboardLargeScreenNav title="Invite Team"/>
          <Row>
            <Col md={12} className="kisls">
              <div className="kdashheader npps nuhead">
                <div className="fjss">
                  <div>
                    {" "}
                    <span className="kdashheaderlight idds">
                      Hi <span className="ksname"> Organization Name</span>
                      <span> (organizationID)</span>
                    </span>
                  </div>
                </div>
              </div>
              <div className="messageerit findbelow">
                Enter the Email of Team Members to send an invite
              </div>
              <Link to="">
                <Button className="addmsg">
                  Send Invitation
                </Button>
                </Link>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default OrganizationDashboardInvite;

const moment = require("moment");

export const formatTime = (date) => {
  const dateTime = moment(date).format("do MMM YYYY");
  return dateTime;
};

export const checkIfIsOdd = (n) => {
  return Math.abs(n % 2) == 1;
};
export  const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

import * as React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./../kegennidashboard.css";
import DashboardLargeScreenNav from "../DashboardLargeScreenNav";
import { Button, Form, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import userimg from "../../../assets/userimg.png";
import noplan from "../../../assets/oopsimg.svg";
import searchicon from "../../../assets/searchicon.png";
import AdminSideBar from "./AdminSideBar";
import AdminNavBar from "./AdminNavBar";
import prevpage from "../../../assets/prevpage.svg";
import nextImg from "../../../assets/nextpage.svg";
import ellipsis from "../../../assets/ellipsis.svg";
import { useState } from "react";
import "./adminModal.css";
import dev from "../../../assets/dev.svg";
import userphoto from "../../../assets/avatar.svg";
import intern from "../../../assets/intern.svg";
import interviewed from "../../../assets/interviewed.svg";
import point from "../../../assets/point.svg";
import { API } from "../../../config";
import Axios from "axios";
import briefcase from "../../../assets/briefcase.png";
import mappin from "../../../assets/mappin.png";
import mail from "../../../assets/mail.png";
import link from "../../../assets/link.png";
import iphone from "../../../assets/phone.png";
import edit from "../../../assets/edit.png";
import preloader from "../../../assets/preloader.gif";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import failedNotice from "../../../assets/failedNotice.png";

const AdminTeamMembers = () => {
  const [state, setState] = useState<any>({
    isOpen: false,
    isOpen2: false,
    isOpen3: false,
    labels: [],
    isloading: false,
    secondloading: false,
    nextLink: "",
    prevLink: "",
    admin: [],
    founduser: {},
    ListOfTags: [],
    searchInput: "",
    selectedId: "",
    totalPages: "",
    newtag: "",
    currentPage: "",
    individualData: "",
    search_value: false,
    view_detail: false,
    first_name: "",
    last_name: "",
    removeMail: "",
    removeId: "",
  });
  const [modState, setModState] = useState<any>({
    accessCheck: false,
    confirm: false,
  });
  const { accessCheck, confirm } = modState;
  const openAccessCheck = () => {
    setModState({
      ...modState,
      accessCheck: true,
    });
  };
  const closeAccessCheck = () => {
    setModState({
      ...modState,
      accessCheck: false,
    });
  };
  const closeConfirmationModal = () => {
    setModState({
      ...modState,
      confirm: false,
    });
  };
  const openConfirmationModal = () => {
    setModState({
      ...modState,
      confirm: true,
    });
  };
  const removeMailModal = (x) => {
    setState({
      ...state,
      removeMail: x.email,
      removeId: x.id,
    });
    return openConfirmationModal();
  };
  const removeEmail = () => {
    const availableToken = localStorage.getItem("userToken");
    const token = availableToken
      ? JSON.parse(availableToken)
      : window.location.assign("/admin/signin");
      const data = {
        email: removeMail,
      }
    Axios
      .all([
        Axios.post(`${API}/admin/delete-single-user`, data, {
          headers: { Authorization: `Token ${token}` },
        }),
      ])
      .then(
        Axios.spread((res1) => {
        //   //console.log(res1);
          if (res1.status === 200) {
            notify(res1.data.message);
          }
          Axios
            .all([
              Axios.get(`${API}/admin/user-list`, {
                headers: { Authorization: `Token ${token}` },
              }),
            ])
            .then(
              Axios.spread((res2) => {
                // //console.log(res2);
                if (res2.status === 200) {
                  setState({
                    ...state,
                    email: "",
                    admin: [...res2?.data?.results],
                    prevLink: res2?.data?.previous,
                    nextLink: res2?.data?.next,
                    totalPages: res2?.data?.total_pages,
                    currentPage: res2?.data?.page,
                  });
                }
              })
            );
          return closeConfirmationModal();
        })
      )
      .catch(() => {
        notify("Failed to delete, please try again later");
      });
  };
  const openModal = () => {
    const availablelabels: any = localStorage.getItem("memberlabels");
    const membersLabels: any = JSON.parse(availablelabels);
    //console.log(membersLabels);
    setState({
      ...state,
      isOpen: true,
      isOpen2: false,
      labels: [...membersLabels],
    });
  };
  const openModal2 = () => {
    setState({
      ...state,
      isOpen: false,
      isOpen2: true,
    });
  };
  const openModal3 = () => {
    setState({
      ...state,
      isOpen: false,
      isOpen2: false,
      isOpen3: true,
    });
  };
  const closeModal = () => {
    setState({
      ...state,
      isOpen: false,
    });
  };
  const closeModal2 = () => {
    setState({
      ...state,
      isOpen2: false,
    });
  };
  const closeModal3 = () => {
    setState({
      ...state,
      isOpen2: false,
      isOpen3: false,
    });
  };
  const deleteLabel = (searchlabel) => {
    setState({
      ...state,
      secondloading: true,
    });
    const membersId: any = localStorage.getItem("memberid");
    const memid = JSON.parse(membersId);
    const availableToken = localStorage.getItem("userToken");
    const token = availableToken
      ? JSON.parse(availableToken)
      : window.location.assign("/admin/signin");
    Axios.get(`${API}/admin/delete-user-label/${memid}/${searchlabel}`, {
      headers: { Authorization: `Token ${token}` },
    })
      .then((res) => {
        //console.log(res);
        const result: any = [];
        labels.forEach((data: string) => {
          if (searchlabel !== data) {
            result.push(data);
          }
        });
        setState({
          ...state,
          labels: [...result],
        });
        notify("successfully deleted tag from user");
      })
      .catch((err) => {
        setState({
          ...state,
          secondloading: false,
        });
        notify("failed to delete tag from user");
        //console.log(err.response);
      });
  };
  const handleLabelChange = (e) => {
    if (!labels.includes(e.target.value)) {
      setState({
        ...state,
        labels: [...labels, e.target.value],
      });
    }
  };
  const {
    admin,
    nextpage,
    totalPages,
    currentPage,
    ListOfTags,
    newtag,
    labels,
    nextLink,
    prevLink,
    selectedId,
    individualData,
    secondloading,
    isloading,
    searchInput,
    search_value,
    view_detail,
    first_name,
    last_name,
    removeMail,
    removeId,
  }: any = state;
  React.useEffect(() => {
    setState({
      ...state,
      secondloading: true,
    });
    const availableToken = localStorage.getItem("userToken");
    const token = availableToken
      ? JSON.parse(availableToken)
      : window.location.assign("/admin/signin");
    Axios.all([
      Axios.get(`${API}/admin/user-list`, {
        headers: { Authorization: `Token ${token}` },
      }),
      Axios.get(`${API}/admin/tags`, {
        headers: { Authorization: `Token ${token}` },
      }),
      Axios.get(`${API}/admin/access`, {
        headers: { Authorization: `Token ${token}` },
      }),
      Axios.get(`${API}/dashboard/profile`, {
        headers: { Authorization: `Token ${token}` },
      }),
    ])
      .then(
        Axios.spread((res1, res2, res3, res4) => {
          //console.log(res1);
          //console.log(res2);
          //console.log(res3);
          setState({
            ...state,
            admin: [...res1.data.results],
            nextLink: res1.data.next,
            prevLink: res1.data.previous,
            totalPages: res1.data.total_pages,
            currentPage: res1.data.page,
            ListOfTags: res2.data.reverse(),
            secondloading: false,
            search_value: res3.data.search,
            view_detail: res3.data.view_detail,
            first_name: res4.data.first_name,
            last_name: res4.data.last_name,
          });
          //console.log(res1);
        })
      )
      .catch((err) => {
        setState({
          ...state,
          secondloading: false,
        });
        //console.log(err);
      });
  }, []);
  const loadAll = () => {
    setState({
      ...state,
      secondloading: true,
    });
    const availableToken = localStorage.getItem("userToken");
    const token = availableToken
      ? JSON.parse(availableToken)
      : window.location.assign("/admin/signin");
    Axios.all([
      Axios.get(`${API}/admin/user-list`, {
        headers: { Authorization: `Token ${token}` },
      }),
      Axios.get(`${API}/admin/tags`, {
        headers: { Authorization: `Token ${token}` },
      }),
    ])
      .then(
        Axios.spread((res1, res2) => {
          //console.log(res1);
          //console.log(res2);
          setState({
            ...state,
            admin: [...res1.data.results],
            nextLink: res1.data.next,
            prevLink: res1.data.previous,
            totalPages: res1.data.total_pages,
            currentPage: res1.data.page,
            ListOfTags: res2.data,
            secondloading: false,
          });
          //console.log(res1);
        })
      )
      .catch((err) => {
        setState({
          ...state,
          secondloading: false,
        });
        //console.log(err);
      });
  };
  const LoadNewData = () => {
    const availableToken = localStorage.getItem("userToken");
    const token = availableToken
      ? JSON.parse(availableToken)
      : window.location.assign("/admin/signin");
    Axios.get(`${nextLink}`, {
      headers: { Authorization: `Token ${token}` },
    })
      .then((res) => {
        window.scrollTo(-0, -0);
        //console.log(res);
        setState({
          ...state,
          admin: [...res.data.results],
          nextLink: res.data.next,
          prevLink: res.data.previous,
          totalPages: res.data.total_pages,
          currentPage: res.data.page,
        });
        //console.log(res);
      })
      .catch((err) => {
        //console.log(err);
      });
  };

  const search = () => {
    if (search_value === false) {
      return openAccessCheck();
    }
    setState({
      ...state,
      secondloading: true,
    });
    const availableToken = localStorage.getItem("userToken");
    const token = availableToken
      ? JSON.parse(availableToken)
      : window.location.assign("/admin/signin");
    Axios.get(`${API}/admin?search=${searchInput}`, {
      headers: { Authorization: `Token ${token}` },
    })
      .then((res) => {
        //console.log(res);
        setState({
          ...state,
          admin: [...res.data.results],
          nextLink: res.data.next,
          prevLink: res.data.previous,
          totalPages: res.data.total_pages,
          currentPage: res.data.page,
          secondloading: false,
        });
        //console.log(res);
      })
      .catch((err) => {
        setState({
          ...state,
          secondloading: false,
        });
        //console.log(err);
      });
  };
  const viewProfile = (id) => {
    if (view_detail === false) {
      return openAccessCheck();
    }
    let resultz: any = [];
    admin.forEach((data) => {
      if (id == data.id) {
        // resultz = [data];
        setState({
          ...state,
          founduser: resultz[0],
          individualData: data,
          isOpen: false,
          isOpen2: false,
          isOpen3: true,
        });
      }
    });
  };
  const onchange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
      isOpen3: false,
    });
  };
  const LoadOldData = () => {
    const availableToken = localStorage.getItem("userToken");
    const token = availableToken
      ? JSON.parse(availableToken)
      : window.location.assign("/admin/signin");
    Axios.get(`${prevLink}`, {
      headers: { Authorization: `Token ${token}` },
    })
      .then((res) => {
        window.scrollTo(-0, -0);
        //console.log(res);
        setState({
          ...state,
          admin: [...res.data.results],
          nextLink: res.data.next,
          prevLink: res.data.previous,
          totalPages: res.data.total_pages,
          currentPage: res.data.page,
        });
        //console.log(res);
      })
      .catch((err) => {
        //console.log(err);
      });
  };

  const notify = (message: string) => toast(message, { containerId: "k" });

  const getLabelById = (id) => {
    localStorage.setItem("memberid", JSON.stringify(id));
    setState({
      ...state,
      isloading: true,
      selectedId: id,
    });
    const availableToken = localStorage.getItem("userToken");
    const token = availableToken
      ? JSON.parse(availableToken)
      : window.location.assign("/admin/signin");
    Axios.get(`${API}/admin/get-user-label/${id}`, {
      headers: { Authorization: `Token ${token}` },
    })
      .then((res) => {
        //console.log(res);
        setState({
          ...state,
          labels: [...res.data.label],
          isloading: false,
          selectedId: id,
        });
        localStorage.setItem("memberlabels", JSON.stringify(res.data.label));
        //console.log(res);
        openModal();
      })
      .catch((err) => {
        //console.log(err);
        notify("Failed to load");
        setState({
          ...state,
          isloading: false,
        });
      });
  };
  const createLabelById = (e) => {
    e.preventDefault();
    // createTag(e);
    const membersId: any = localStorage.getItem("memberid");
    const memid = JSON.parse(membersId);
    //console.log(memid);
    const availableToken = localStorage.getItem("userToken");
    const token = availableToken
      ? JSON.parse(availableToken)
      : window.location.assign("/admin/signin");
    const data = {
      id: memid,
      labels,
    };
    //console.log(data);
    Axios.post(`${API}/admin/user-tag`, data, {
      headers: { Authorization: `Token ${token}` },
    })
      .then((res) => {
        //console.log(res);
        setState({
          ...state,
        });
        notify("Successfully added tag");
        //console.log(res);
        setTimeout(() => {
          closeModal();
          closeModal2();
        }, 2000);
      })
      .catch((err) => {
        //console.log(err);
      });
  };
  const createTag = (e) => {
    if (newtag.trim() == "") {
      return notify("Please enter label name");
    }
    e.preventDefault();
    const membersId: any = localStorage.getItem("memberid");
    const memid = JSON.parse(membersId);
    //console.log(memid);
    const availableToken = localStorage.getItem("userToken");
    const token = availableToken
      ? JSON.parse(availableToken)
      : window.location.assign("/admin/signin");
    const data = {
      tag: newtag,
    };
    //console.log(data);
    Axios.post(`${API}/admin/create-tag`, data, {
      headers: { Authorization: `Token ${token}` },
    })
      .then((res) => {
        setState({
          ...state,
          ListOfTags: [...ListOfTags, newtag],
          newtag: "",
        });
      })
      .catch((err) => {
        //console.log(err);
        notify("failed to create tag");
      });
  };
  //console.log(state.founduser);
  return (
    <>
      <Container fluid={true} className="contann122">
        <AdminNavBar teammembers={true} />
        <Row>
          <AdminSideBar teammembers={true} />
          <Col md={10} sm={12} className="prm newprm">
            <DashboardLargeScreenNav title="Team Members" />
            <Row>
              <Col md={12} className="kisls">
                {isloading && (
                  <div className="preloader1">
                    <img src={preloader} className="preloader" alt="" />
                  </div>
                )}
                {secondloading && (
                  <div className="preloader2">
                    <img src={preloader} className="preloader" alt="" />
                  </div>
                )}

                <div className="kdashheader npps nuhead">
                  <div className="fjss">
                    <div>
                      {" "}
                      <span className="kdashheaderlight idds hii">
                        Hi <span className="ksname">{first_name ? first_name + " " + last_name : ""}</span>
                      </span>
                    </div>
                  </div>
                  <div className="searchbar">
                    <img className="searchicon" src={searchicon} onClick={search} alt="search" />
                    <input
                      type="search"
                      className="searchit"
                      value={searchInput}
                      name={"searchInput"}
                      onChange={onchange}
                      onKeyPress={(e) => {
                        //console.log(e);
                        if (e.key == "Enter") {
                          search();
                        }
                      }}
                      placeholder="Search by name, label or thematic area"
                    />
                  </div>
                </div>
                <div className="messageerit findbelow msgsum findit">
                  Find below a list of all your team members
                </div>
                <div className="teammembr teamheading">
                  <div className="tname memberdet">
                    <div>Name</div>
                  </div>
                  <div className="tarea themarea">
                    <div>Thematic Area</div>
                  </div>
                  <div className="tteam team">
                    <div>Team</div>
                  </div>
                  <div className="tstatus adminstat">
                    <div>Status</div>
                  </div>
                  <div className="tteam moreopt">Date </div>
                    <div className="adminbtn">{" "}</div>
                    <div className="admin-delete"></div>
                </div>
                {admin.length > 0 &&
                  admin.map((x, i) => (
                    <div className="msgs teammembr" key={i}>
                      <div className="fromerit summary admin-body">
                        {/* <div className="userr imagesec"> */}
                          {/* <img
                            className="user_image"
                            src={userimg}
                            alt="user image"
                          /> */}
                        {/* </div> */}
                        <div className="memberdet">
                          <div>
                            <div className="lowerr nulower">Name</div>
                            <div className="userrdet1 det1">
                              {x.first_name} {x.last_name}
                            </div>
                            <div className="userrdet2 memb">{x.email}</div>
                          </div>
                        </div>
                        <div className="themarea">
                          <div className="lowerr nulower">Thematic Area</div>
                          <div>
                            {x.thematic_area ? x.thematic_area : "--/--"}
                          </div>
                        </div>
                        <div className="team">
                          <div className="lowerr nulower">Team</div>
                          <div>{x.team ? x.team : "--/--"}</div>
                        </div>
                        <div className="adminstat">
                          <div className="lowerr nulower sess">
                            Assessment Status
                          </div>
                          <span
                            className={!x.assessment_status ? "pend" : "complt"}
                          >
                            {x.assessment_status ? "Complete" : "Pending"}
                          </span>
                        </div>
                        <div className="moreopt">{x?.date_joined}</div>
                        <div className="adminbtn">
                          {x.completed_profile && (
                            <div
                              className="sendIV viewit"
                              onClick={() => viewProfile(x.id)}
                            >
                              View Details
                            </div>
                          )}
                          {!x.completed_profile && (
                            <div className={"sendIV viewit disabledview"}>
                              View Details
                            </div>
                          )}
                        </div>
                      <i
                        className="fa fa-trash admin-delete"
                        onClick={() => removeMailModal(x)}
                        ></i>
                      </div>
                    </div>
                  ))}
                {admin.length == 0 && !secondloading && (
                  <>
                    <div className="norec pad23">
                      <img
                        src={noplan}
                        className="norecommendations"
                        alt="norecommendations"
                      />
                      <div className="udont frtr2">No match found</div>
                      <div className="udont ">
                        {" "}
                        <span
                          className="takeassesment12 ftre"
                          onClick={loadAll}
                        >
                          View All
                        </span>
                      </div>
                    </div>
                  </>
                )}
                {admin.length > 0 && (
                  <div className="next_page">
                    <div>
                      Displaying <span className="page_num">{currentPage}</span>{" "}
                      out of <span className="page_num">{totalPages}</span>
                    </div>
                    <div>
                      {prevLink && (
                        <img
                          className="page_change"
                          src={prevpage}
                          onClick={LoadOldData}
                          alt="previous page"
                        />
                      )}

                      {nextLink && (
                        <img
                          onClick={LoadNewData}
                          className="page_change"
                          src={nextImg}
                          alt="next page"
                        />
                      )}
                    </div>
                  </div>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Modal
        className="smallmodal"
        show={state.isOpen}
        centered={true}
        onHide={closeModal}
      >
        <Modal.Body>
          <>
            {labels && labels.length > 0 ? (
              <div className="modlist">
                {labels[0] && (
                  <div className="dev">
                    <img className="labelicon" src={dev} alt="developer" />
                    <span>
                      {labels[0]}{" "}
                      <span
                        onClick={() => deleteLabel(labels[0])}
                        className="deletelabel"
                      >
                        &times;
                      </span>
                    </span>
                  </div>
                )}
                {labels[1] && (
                  <div className="intern">
                    <img className="labelicon" src={intern} alt="intern" />
                    <span>
                      {labels[1]}{" "}
                      <span
                        onClick={() => deleteLabel(labels[1])}
                        className="deletelabel"
                      >
                        &times;
                      </span>
                    </span>
                  </div>
                )}
                {labels[2] && (
                  <div className="interviewed">
                    <img
                      className="labelicon"
                      src={interviewed}
                      alt="interviewed"
                    />
                    <span>
                      {labels[2]}{" "}
                      <span
                        onClick={() => deleteLabel(labels[2])}
                        className="deletelabel"
                      >
                        &times;
                      </span>
                    </span>
                  </div>
                )}
                {labels[3] && (
                  <div className="intern">
                    <img className="labelicon" src={intern} alt="intern" />
                    <span>
                      {labels[3]}{" "}
                      <span
                        onClick={() => deleteLabel(labels[3])}
                        className="deletelabel"
                      >
                        &times;
                      </span>
                    </span>
                  </div>
                )}
                {labels[4] && (
                  <div className="dev">
                    <img className="labelicon" src={dev} alt="developer" />
                    <span>{labels[4]}</span>
                    <span
                      onClick={() => deleteLabel(labels[4])}
                      className="deletelabel"
                    >
                      &times;
                    </span>
                  </div>
                )}
              </div>
            ) : (
              "This user does not have a label"
            )}
            <hr />
            <div className="addit" onClick={openModal2}>
              <span>Create / Edit Label</span>
              <img className="point" src={point} alt="add or edit label" />
            </div>
          </>
        </Modal.Body>
      </Modal>
      <Modal
        className="smallmodal"
        show={state.isOpen2}
        centered={true}
        onHide={closeModal2}
      >
        <Modal.Body>
          <div className="slctlab">Please Select a label</div>
          <Form action="" className="slctform">
            <>
              <div className="boxes">
                <input
                  className="cnew"
                  value={newtag}
                  name={newtag}
                  onChange={(e) => {
                    setState({
                      ...state,
                      newtag: e.target.value,
                    });
                  }}
                  placeholder="Create New Label"
                />
                <div className="text-right">
                  <span className="addnw1" onClick={createTag}>
                    {" "}
                    Add new
                  </span>
                </div>
              </div>
              {ListOfTags?.map((tag, i) => (
                <div className="boxes dsx" key={i}>
                  <input
                    id="devchck"
                    name={tag}
                    onChange={handleLabelChange}
                    value={tag}
                    type="checkbox"
                  />
                  <label htmlFor="devchck">{tag} </label>
                </div>
              ))}
            </>
            <hr />
            <Button
              type="submit"
              onClick={createLabelById}
              className="sendIV fsave"
            >
              Save
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal show={state.isOpen3} centered={true} onHide={closeModal3}>
        <div className="closeselc2a">
          <span className="closeselc2b" onClick={closeModal3}>
            &times;
          </span>
        </div>
        <Modal.Body className="profl">
          <div className="userav">
            <img src={userphoto} className="userphoto" alt="userphoto" />
          </div>
          <div className="slctlabaa">
            {state?.founduser?.first_name} {state?.founduser?.last_name}
          </div>
          <div className="instruc">{individualData?.email}</div>
          <Form action="" className="slctform">
            <div className="boxes">
              <img
                src={briefcase}
                className="briefcase mail112"
                alt="briefcase"
              />
              <label htmlFor="devchck" className="txff">
                {individualData?.job_description}
              </label>
            </div>
            <div className="boxes">
              <img src={mappin} className="mappin mail112" alt="mappin" />
              <label htmlFor="viewedchck" className="txff">
                {individualData.address}
              </label>
            </div>
            <div className="boxes">
              <img src={mail} className="mail112" alt="mail" />
              <label htmlFor="internchck" className="txff">
                {individualData?.email}
              </label>
            </div>
            <div className="boxes">
              <img src={iphone} className="mail112" alt="mail" />
              <label htmlFor="devavo" className="txff">
                {individualData?.phone}
              </label>
            </div>
            <div className="boxes">
              {/* <img src={briefcase} className="mail112" alt="mail" /> */}
              <label htmlFor="newlabel" className="txff">
                {/* Available for Projects */}
              </label>
            </div>
            <hr />
            <div className="clss1a">
              {individualData.assessment_status && (
                <Button type="submit" className="sendIV fsave clss1aa1">
                  <Link
                    to={`/admin/result/${individualData.email}`}
                    target={"blank"}
                  >
                    Assessment result
                  </Link>
                </Button>
              )}
              {individualData.completed_profile && (
                <Button type="submit" className="sendIV fsave clss1aa">
                  <Link
                    to={`/users_profile/${individualData.email}`}
                    target={"blank"}
                  >
                    Professional profile
                  </Link>
                </Button>
              )}
            </div>
          </Form>
        </Modal.Body>
      </Modal>{" "}
      <ToastContainer
        enableMultiContainer
        containerId={"k"}
        toastClassName="bg-info text-white"
        hideProgressBar={true}
        position={toast.POSITION.TOP_CENTER}
      />
      <Modal show={accessCheck} onHide={closeAccessCheck} centered>
        <Modal.Body>
          <div className="text-center">
            {" "}
            <img
              src={failedNotice}
              className="failedNotice"
              alt="failedNotice"
            />{" "}
          </div>
          <div className="nono"> Oh No! </div>
          <div className="nonosubtxt">
            This feature is not available on your current plan. <br /> Please
            upgrade your plan to access it.
          </div>
          {/* <div className="text-center">
            <button
              className="nonobtn"
              onClick={() =>
                window.location.assign("/dashboardsubscriptionplan")
              }
            >
              View Current Plan
            </button>
          </div> */}
        </Modal.Body>
      </Modal>
      <Modal show={confirm} onHide={closeConfirmationModal} centered>
        <Modal.Body>
          <div className="exittxt">
            Delete <span className="exittxtsub">{removeMail}</span>'s member account?
          </div>
          <div className="exitbtns">
            <button className="exitbtn1" onClick={closeConfirmationModal}>
              No
            </button>
            <button className="exitbtn2" onClick={removeEmail}>
              Yes
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AdminTeamMembers;

import * as React from "react";
import "../Home/Home.css";
import "./clarityforteams.css";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Footer from "../HomeComponents/footer";
import Navbar from "../HomeComponents/navbar";
// team

import oneofour from "../../../assets/cutelady.png";
import oneofour1 from "../../../assets/joinhands.png";
import oneofour2 from "../../../assets/shakehand.png";
import oneofour3 from "../../../assets/typehand.png";
import firstImage from "../../../assets/7.png";
import { Link } from "react-router-dom";
import { useEffect } from "react";

export const AllPlansDashboardUse = (props: any) => {
  useEffect(() => {
    let resultareawithtitle: string = window.location.hash;
    resultareawithtitle = resultareawithtitle.substring(1);
  }, []);

  return (
    <div>
      <Container fluid={true}>
        <Row className="firstrowcf cftcontent bgwcf offe11 klsos">
          {/* <div className="pricewraper">
            <div className="plan_">Member</div>
            <div className="personalityss">0-3 years experience </div>
            <br />
            <div className="personality dd11">
              Create a professional profile
            </div>
            <div className="personality dd11">
              Access internal publications and newsletters
            </div>
            <div className="Strengths dd11">Join Blogs/Forums/Webinars </div>
            <div className="Weaknesses dd11">Publish your CV/Resume </div>
            <div className="Leadership dd11">
              {" "}
              Bi-annual Competence Assessment
            </div>
            <div className="Leadership dd11">
              Discounted workshops and trainings.{" "}
            </div>
            <div className="Leadership dd11">
              Opportunities to work on volunteer & paid educational reform
              projects
            </div>
            <div className="amount12a">
              {" "}
              <b> NGN 5,000</b>
              <span className="period1">Annually</span>
            </div>
            <div>
              <Link className="amlink" to={`/signup/${5000}`}>
                {" "}
                <button className="getstarted">Get Started</button>
              </Link>
            </div>
          </div> */}
          <div className="pricewraper newwrapper">
            <div className="plan_">Community Member</div>
            <br />
            <div className="personality dd11"> </div>
            <div className="personality dd11">
              Create a professional profile
            </div>
            <div className="personality dd11">
              Access internal publications and newsletters
            </div>
            <div className="Strengths dd11">Join Blogs/Forums/Webinars </div>
            <div className="Weaknesses dd11">Publish your CV/Resume </div>
            <div className="Leadership dd11">
              {" "}
              Periodic Competence Assessment
            </div>
            <div className="Leadership dd11">Join Thematic Groups</div>
            <div className="Leadership dd11">
              Discounted workshops and trainings
            </div>
            <div className="Leadership dd11">
              Opportunities to volunteer on education reform projects
            </div>
            <div className="amount12a">
              {" "}
              <b className="nobreak"> NGN 10,000</b>
              <span className="period1">Annually </span>
            </div>
            <div>
              <Link className="amlink" to={`/paynow/${10000}/Community`}>
                {" "}
                <button className="getstarted">Make Payment</button>
              </Link>
            </div>
          </div>
          <div className="pricewraper newwrapper">
            <div className="plan_">Professional Member</div>
            <br />
            <div className="personality dd11"></div>
            <div className="personality dd11">
              Create a professional profile
            </div>
            <div className="personality dd11">
              Access internal publications and newsletters
            </div>
            <div className="Strengths dd11">Join Blogs/Forums/Webinars </div>
            <div className="Weaknesses dd11">Publish your CV/Resume </div>
            <div className="Strongw dd11">Join Thematic Groups </div>
            <div className="Strongw dd11">Periodic Competence Assessment</div>
            <div className="Strongw dd11">Join Thematic Groups </div>
            <div className="Strongw dd11">
              Discounted workshops and trainings
            </div>
            <div className="Strongw dd11">
              Opportunities to earn on education reform projects
            </div>
            <div className="Leadership dd11">
              Lists services and products on our website + Discounted
              advertising (20%)
            </div>
            <div className="Leadership dd11">Referrals </div>
            <div className="amount12a">
              {" "}
              <b className="nobreak"> NGN 25,000 </b>
              <span className="period1">Annually</span>
            </div>
            <div>
              <Link className="amlink" to={`/paynow/${25000}/Professional`}>
                {" "}
                <button className="getstarted">Make Payment</button>
              </Link>
            </div>
          </div>

          <div className="pricewraper newwrapper">
            <div className="plan_">Corporate Member</div>
            <br />
            <div className="Strengths dd11">Founder/CEO</div>
            <div className="Strengths dd11">5 team members </div>
            <div className="Strengths dd11"> Create professional profiles </div>
            <div className="Strengths dd11">
              {" "}
              Access internal publications and newsletters
            </div>
            <div className="Strengths dd11">Join Blogs/Forums/Webinars </div>
            <div className="Weaknesses dd11">Publish your CV/Resume </div>
            <div className="Strongw dd11">Join Thematic Groups </div>
            <div className="Leadership dd11">Banner Advertising (50%) </div>
            <div className="Leadership dd11">
              Periodic Competence Assessment for Organisation members{" "}
            </div>
            <div className="Leadership dd11">
              Publish CV/Resume of Organisation members
            </div>
            <div className="Leadership dd11">Join Thematic Groups </div>
            <div className="Leadership dd11">
              Discounted workshops and trainings
            </div>
            <div className="Leadership dd11">
              Opportunities to collaborate and earn on education reform projects
            </div>
            <div className="Leadership dd11">
              Lists services and products on our website + Discounted
              advertising (50%)
            </div>
            <div className="Leadership dd11">Referrals </div>
            <div className="Leadership dd11">
              Host Events & Exhibit at Events
            </div>
            <div className="Leadership dd11">
              Represent ERIT at designated events
            </div>
            <div className="Leadership dd11">Join Business Round-tables</div>
            <div className="Leadership dd11">Co-Host Webinar Presentations</div>
            <div className="amount12a">
              {" "}
              <Link className="amlink" to={`/paynow/${100000}`}>
                <b className="nobreak"> NGN 100,000 </b>
              </Link>
              <div className="period1">Annually</div>
            </div>
            <div className="Strongw dd11">
              <Link className="amlink" to={`/paynow/${100000}/Corporate`}>
                <button className="getstarted1"> Make Payment</button>
              </Link>
            </div>
          </div>
        </Row>
        <Footer />
      </Container>
    </div>
  );
};

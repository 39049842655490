import * as React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./kegennidashboard.css";
import avatar from "../../assets/avatar.svg";
import SideBarNewDashboard from "./SideBarNewDashboard";
import Axios, { AxiosResponse } from "axios";
import { API } from "../../config";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DashboardNav from "./DashboardNavBar";
import DashboardLargeScreenNav from "./DashboardLargeScreenNav";
import "./dashboard.css";

class NewDashboardSettings extends React.Component {
  state: any = {
    last_name: "",
    first_name: "",
    errorMessage: "",
    email: "",
    shouldFillProfilesettings: true,
    shouldFillCvBuilder: false,
    address: "",
    phone: "",
    job_description: "",
    website_link: "",
    thematic_area: "",
    successMsg: false,
    isLoading: false,
    showWarning: false,
    image: "",
    imageName: "",
    membershiplan: "",
    width: 100,
    submitLoading: false,
    uploadLoading: false,
  };
  validateForm = (e) => {
    const {
      first_name,
      last_name,
      email,
      address,
      phone,
      job_description,
      thematic_area,
      website_link,
    } = this.state;
    if (
      first_name === "" ||
      last_name === "" ||
      email === "" ||
      address === "" ||
      phone === "" ||
      job_description === "" ||
      thematic_area === ""
    ) {
      return this.notify("Please fill the required fields");
    } else {
      this.submitForm(e);
    }
  };
  handleImageChange = (e) => {
    this.setState({
      // image: e.target.files[0],
      uploadLoading: true,
      imageName: e.target.files[0].name,
    });
    const img = e.target.files[0];
    this.uploadImage(img);
  };

  uploadImage = (img) => {
    const self: any = this;
    const { image } = this.state;
    //console.log(image);
    const availableToken = localStorage.getItem("userToken");
    const token = availableToken ? JSON.parse(availableToken) : "";
    const data = new FormData();
    data.append("image", img);
    Axios.post<any, AxiosResponse<any>>(
      `${API}/dashboard/upload-profile-photo`,
      data,
      {
        headers: { Authorization: `Token ${token}` },
      }
    )
      .then((res) => {
        this.setState({
          uploadLoading: false,
        });
        //console.log(res.data);
        this.notify("Successful");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((err) => {
        this.setState({
          uploadLoading: false,
        });
        //console.log(err.response);
        this.notify("failed");
        if (err) {
          //console.log(err);
        }
      });
  };
  submitForm = (e) => {
    this.setState({
      submitLoading: true,
    });
    const self: any = this;
    e.preventDefault();
    const {
      first_name,
      last_name,
      email,
      address,
      phone,
      job_description,
      thematic_area,
      website_link,
      image,
    } = this.state;
    const website_url = website_link;
    //console.log(image);
    const availableToken = localStorage.getItem("userToken");
    const token = availableToken ? JSON.parse(availableToken) : "";
    const data = new FormData();
    // data.append("image", image);
    data.append("last_name", last_name);
    data.append("first_name", first_name);
    data.append("email", email);
    data.append("address", address);
    data.append("phone", phone);
    data.append("thematic_area", thematic_area); 
    data.append("job_description", job_description);
    data.append("website_link", website_link);
    Axios.post<any, AxiosResponse<any>>(`${API}/dashboard/profile`, data, {
      headers: { Authorization: `Token ${token}` },
    })
      .then((res) => {
        this.setState({
          submitLoading: false,
        });
        //console.log(res.data);
        this.notify("Successful!");
        setTimeout(() => {
          !this.state.shouldFillCvBuilder &&
            self.props.history.push("/profilebuilder");
        }, 2000);
      })
      .catch((err) => {
        //console.log(err.response);
        this.setState({
          submitLoading: false,
        });
        this.notify("failed");
        if (err) {
          //console.log(err);
        }
      });
  };
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  fileInput: HTMLInputElement | null | undefined;
  componentDidMount() {
    this.setState({ isLoading: true });
    const availableToken = localStorage.getItem("userToken");
    const token = availableToken
      ? JSON.parse(availableToken)
      : window.location.assign("/signin");
    const data = {};
    Axios.get<any, AxiosResponse<any>>(`${API}/dashboard/profile`, {
      headers: { Authorization: `Token ${token}` },
    })
      .then((response) => {
        //console.log(response);
        if (response.status === 200) {
          this.setState({
            ...response.data,
          });
        }
      })
      .catch((error) => {
        //console.log(error.response);
        if (error && error.response && error.response.data) {
          this.setState({
            errorMessage: error.response.data[0].message,
            isLoading: false,
          });
        }
        this.setState({
          errorMessage: "failed",
          isLoading: false,
        });
      });
  }
  handleRef = (data) => {
    //console.log(data);
  };
  notify = (message: string) => toast(message, { containerId: "i" });
  render() {
    const {
      imageName,
      email,
      address,
      image,
      phone,
      first_name,
      last_name,
      job_description,
      thematic_area,
      website_link,
      membershiplan,
      submitLoading,
      uploadLoading,
    } = this.state;
    // //console.log(image.length);
    return (
      <>
        <Container fluid={true} className="contann122">
          <DashboardNav settings={true} />
          <Row>
            <SideBarNewDashboard settings={true} />
            <ToastContainer
              enableMultiContainer={false}
              containerId={"i"}
              toastClassName="bg-info text-white"
              hideProgressBar={true}
              position={toast.POSITION.TOP_CENTER}
            />
            <Col md={10} sm={12} className="prm">
              <DashboardLargeScreenNav title="Settings" />
              <Row>
                <Col md={11} className="kisls">
                  <div className="kdashheader uidd11">
                    <div className="floo">
                      <div className="smll">
                        {" "}
                        <div className="smalls">
                          <img
                            src={
                              image !== ""
                                ? image
                                : image.length == undefined
                                ? avatar
                                : avatar
                            }
                            className="avatar avar"
                            alt="avatar"
                          />
                        </div>
                        <div>
                          <input
                            type="file"
                            onChange={this.handleImageChange}
                            style={{ display: "none" }}
                            ref={(fileInput) => (this.fileInput = fileInput)}
                          />
                          <div
                            className="filechan savebtn savecv"
                            onClick={() => this.fileInput?.click()}
                          >
                            {uploadLoading ? "Uploading..." :"Upload Image"}
                          </div>
                          {imageName && (
                            <span className="infoforimage">{imageName}</span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <Row>
                    <Col md={12}>
                      {/* {this.state.shouldFillProfilesettings && (
                        <div className="detailsa">
                          Please complete your profile
                        </div>
                      )} */}
                      <hr />
                      <Row className="rowla">
                        <Col md={6}>
                          <div className="whatdoudo">
                            First Name{" "}
                            <span className="compulsory_info">*</span>
                          </div>
                          <textarea
                            name="first_name"
                            value={first_name}
                            onChange={this.handleChange}
                            className="form-control jobr subhyt"
                            placeholder=""
                          ></textarea>
                        </Col>
                        <Col md={6}>
                          <div className="whatdoudo">
                            Last Name <span className="compulsory_info">*</span>
                          </div>
                          <textarea
                            name="last_name"
                            value={last_name}
                            onChange={this.handleChange}
                            className="form-control jobr subhyt"
                            placeholder=""
                          ></textarea>
                        </Col>
                      </Row>
                      <Row className="rowla">
                        <Col md={6}>
                          <div className="whatdoudo">
                            Address <span className="compulsory_info">*</span>
                          </div>
                          <textarea
                            name="address"
                            value={address}
                            onChange={this.handleChange}
                            className="form-control jobr subhyt"
                            placeholder=""
                          ></textarea>
                        </Col>
                        <Col md={6}>
                          <div className="whatdoudo">
                            Email <span className="compulsory_info">*</span>
                          </div>
                          <textarea
                            name="email"
                            value={email}
                            onChange={this.handleChange}
                            className="form-control jobr subhyt"
                            placeholder=""
                          />
                        </Col>
                      </Row>
                      <Row className="rowla">
                        <Col md={6}>
                          <div className="whatdoudo">
                            Phone Number{" "}
                            <span className="compulsory_info">*</span>
                          </div>
                          <textarea
                            name="phone"
                            value={phone}
                            onChange={this.handleChange}
                            className="form-control jobr subhyt"
                            placeholder=""
                          />
                        </Col>
                        <Col md={6}>
                          <div className="whatdoudo">Website Link </div>
                          <textarea
                            name="website_link"
                            value={website_link}
                            onChange={this.handleChange}
                            className="form-control jobr subhyt"
                            placeholder=""
                          ></textarea>
                        </Col>
                      </Row>
                      <Row className="rowla">
                        <Col md={6}>
                          <div className="whatdoudo">
                            Select Preferred Thematic Area{" "}
                            <span className="compulsory_info">*</span>
                          </div>
                          <select
                            className="form-control jobr subhyt"
                            name="thematic_area"
                            onChange={this.handleChange}
                            id=""
                          >
                            <option>
                              {thematic_area ? thematic_area : ""}
                            </option>
                            <option value="School Leadership and Teacher Education">
                              School Leadership and Teacher Education
                            </option>
                            <option value="Education Reform ">
                              Education Reform
                            </option>
                            <option value="Educational Technology and Infrastructure">
                              Educational Technology and Infrastructure{" "}
                            </option>
                            <option value=" Curriculum Review, Design and Development">
                              Curriculum Review, Design and Development
                            </option>
                            <option value="Media and Advocacy">
                              Media and Advocacy
                            </option>
                            <option value="Research and Policy">
                              Research and Policy
                            </option>
                            <option value="Education Funding & Innovation">
                              Education Funding & Innovation
                            </option>
                            <option value="School Transformation (Primary)">
                              School Transformation (Primary)
                            </option>
                            <option value=" School Transformation (Secondary)">
                              School Transformation (Secondary)
                            </option>
                          </select>
                        </Col>
                        <Col md={6}>
                          <div className="whatdoudo">
                            Occupation{" "}
                            <span className="compulsory_info">*</span>
                          </div>
                          <textarea
                            name="job_description"
                            value={job_description}
                            onChange={this.handleChange}
                            className="form-control jobr subhyt"
                            placeholder=""
                          ></textarea>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <div className="text-right">
                    <div
                      className="savebtn savecv col-md-11"
                      onClick={this.validateForm}
                    >
                      {submitLoading ? "Submitting..." :"Submit"}
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
export default NewDashboardSettings;
